import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import SalesTrackerStore from './../../store'
import {
  MDBIcon,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBCollapse,
  MDBCollapseHeader 
} from 'mdbreact'
import AvPolicyForm from './../AvPolicyForm/AvPolicyForm.component'
import ConfirmDeleteModalComponent from './../../../../components/adminShared/ConfirmDeleteModal/ConfirmDeleteModal.component'

import './AvPoliciesList.component.scss'

const AvPoliciesList = () => 
{
  const [policyIdx, setPolicyIdx] = useState(0),
    [deletePolicy, setDeletePolicy] = useState(null)

  const startPolicy = () => setPolicyIdx(SalesTrackerStore.startPolicy() - 1)

  const onConfirmDelete = (Policy, idx) => {
    if (Policy.isNew()) {
      if (policyIdx === idx) {
        setPolicyIdx(policyIdx - 1)
        SalesTrackerStore.unsetPolicyValidity(policyIdx)
      }
      SalesTrackerStore.Policies = SalesTrackerStore.Policies.filter(
        (P, i) => i !== idx
      )
      return
    }

    setDeletePolicy({ Policy, idx })
  }

  const getPolicyLabel = (Policy, idx) => {
    return (
      <table
        width="100%"
        className="policy-label-table font--lato fw--500 text--white"
      >
        <tbody>
          <tr>
            <td valign="middle" rowSpan="2">
              {idx + 1}.
            </td>
            <td>
              {Policy.isNew() ? '(NEW) ' : ''}
              {Policy.get('av_carrier')}
            </td>
            <td valign="middle" rowSpan="2">
              {idx > 0 ? (
                <MDBBtn
                  block
                  className="view-btn"
                  onClick={() => setPolicyIdx(parseInt(idx))}
                >
                  <MDBIcon icon="eye" />
                  &nbsp;View
                </MDBBtn>
              ) : (
                <></>
              )}
            </td>
            <td valign="middle" rowSpan="2">
              {idx > 0 ? (
                <MDBBtn
                  block
                  className="trash-btn"
                  onClick={() => onConfirmDelete(Policy, idx)}
                >
                  <MDBIcon icon="trash" />
                  &nbsp;Delete
                </MDBBtn>
              ) : (
                <MDBBtn
                  block
                  className="view-btn"
                  onClick={() => setPolicyIdx(parseInt(idx))}
                >
                  <MDBIcon icon="eye" />
                  &nbsp;View
                </MDBBtn>
              )}
            </td>
          </tr>
          <tr>
            <td>{Policy.get('av_coverage')}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  return (
    <div
      id="AvPoliciesListComponent"
      className="accordion md-accordion accordion-1"
    >
      <MDBCollapse isOpen={!SalesTrackerStore.isFetching}>
        <div className="form-row">
          <MDBCol size="12">
            <h6 className="font---lato">
              Policies
              <MDBBtn onClick={() => startPolicy()}>
                <MDBIcon icon="plus" />
                &nbsp;Add Policy
              </MDBBtn>
            </h6>
          </MDBCol>
        </div>
        {SalesTrackerStore.Policies.map((Policy, idx) => (
          <MDBCard
            key={`av-policy-${idx}`}
            style={{ backgroundColor: 'transparent' }}
          >
            <MDBCollapseHeader className="text-uppercase blue lighten-3 z-depth-1">
              {getPolicyLabel(Policy, idx)}
            </MDBCollapseHeader>
            <MDBCollapse isOpen={policyIdx === idx}>
              <MDBCardBody>
                <AvPolicyForm Policy={Policy} idx={idx} />
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>
        ))}
      </MDBCollapse>
      <ConfirmDeleteModalComponent
        confirm={
          deletePolicy &&
          [
            deletePolicy.Policy.get('av_carrier'),
            deletePolicy.Policy.get('av_coverage'),
          ].join(' - ')
        }
        onConfirm={() => {
          if (policyIdx === deletePolicy.idx) setPolicyIdx(0)
          SalesTrackerStore.Policies = SalesTrackerStore.Policies.filter(
            (P, i) => i !== deletePolicy.idx
          )
          deletePolicy.Policy.delete()
          setDeletePolicy(null)
        }}
        shouldEnable={true}
        onCancel={() => {}}
      />
    </div>
  )
}

export default observer(AvPoliciesList)
