import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import UserProfileService from '../../../../shared/services/UserProfile.service'
import UsertypeService from '../../../../shared/services/Usertype.service'
import { MDBContainer, MDBRow, MDBCol, MDBSwitch } from 'mdbreact'

const ProfileCanEnrollOverrideSwitch = ({ onToggle }) => {
  const [isLoading, setIsLoading] = useState(false)

  const meetsRequirement =
    (UserProfileService.isA('internal-staff', true) ||
    UserProfileService.isA('internal-admin', true) ||
    UserProfileService.isA('system-admin', true) ||
    UserProfileService.isA('agency-owner', true)) &&
    UsertypeService.isA(
      'premier-agent',
      UserProfileService.getCurrentUserTypeId()
    )

  const toggle = async () => {
    setIsLoading(true)

    let canEnrollOverride = `${UserProfileService.get('profile_can_enroll_override')}` === '1'

    try {
      UserProfileService.set('profile_can_enroll_override', canEnrollOverride ? '0' : '1')

      await UserProfileService.upsertUserMeta(
        'profile---can-enroll-override',
        canEnrollOverride ? '0' : '1'
      )

      onToggle(!canEnrollOverride)
      setIsLoading(false)
    } catch (error) {
      onToggle(error.message)
      setIsLoading(false)
    }
  }

  const renderButton = () => {
    if (!meetsRequirement) return <></>

    return (
      <MDBContainer
        fluid
        style={{ backgroundColor: 'rgba(255,255,255,0.5)', paddingTop: '10px' }}
      >
        <MDBRow>
          <MDBCol size="6" sm="7" md="9" lg="6">
            <label>
              Enable Enrollment?&nbsp;
              {isLoading ? (
                <>
                  <span>
                    <i className="fa fa-spin fa-spinner"></i>
                  </span>
                </>
              ) : (
                <></>
              )}
            </label>
          </MDBCol>
          <MDBCol size="6" sm="5" md="3" lg="6" style={{ textAlign: 'right' }}>
            <MDBSwitch
              labelLeft={'No'}
              labelRight={'Yes'}
              disabled={!!isLoading}
              checked={`${UserProfileService.get('profile_can_enroll_override')}` === '1'}
              onChange={toggle}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }

  return renderButton()
}

export default observer(ProfileCanEnrollOverrideSwitch)
