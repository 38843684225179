import React from 'react'
import { observer } from 'mobx-react-lite'
import { MDBAlert, MDBSwitch, MDBRow, MDBCol, MDBContainer } from 'mdbreact'

import './ToggleSpending.component.scss'

const ToggleSpending = ({ ControlStore }) => {
  const toggle = async () => {
    try {
      await ControlStore.toggleSpending()
    } catch (ex) {
      console.error(`${ex}`)
    }
  }

  const getColor = () => {
    if (ControlStore.isLoading) return 'warning'
    if (ControlStore.preventSpending === false) return 'success'
    if (ControlStore.preventSpending === true) return 'danger'
    return 'secondary'
  }

  return (
    <MDBAlert color={getColor()}>
      <MDBContainer
        fluid
        id="ToggleSpendingComponent"
        className={
          ControlStore.isLoading
            ? 'is-loading'
            : ControlStore.preventSpending === true
            ? 'is-activated'
            : 'is-deactivated'
        }
      >
        <MDBRow>
          <MDBCol size="12" sm="6" className="label-wrapper">
            <div>
              <label>
                Pause Spending (No Spending)?&nbsp;
                {ControlStore.isLoading ? (
                  <>
                    <span>
                      <i className="fa fa-spin fa-spinner"></i>
                    </span>
                  </>
                ) : (
                  <></>
                )}
                {ControlStore.preventSpending === true ? (
                  <>
                    <br />
                    <small>
                      <strong>(Spending/Spending is Not Permitted)</strong>
                    </small>
                  </>
                ) : (
                  <></>
                )}
                {ControlStore.preventSpending === false ? (
                  <>
                    <br />
                    <small>
                      <strong>(Spending/Spending is Permitted)</strong>
                    </small>
                  </>
                ) : (
                  <></>
                )}
              </label>
              <MDBSwitch
                labelLeft={
                  ControlStore.preventSpending === true ? (
                    <span style={{ color: '#a10a0a', fontWeight: 600 }}>
                      Paused
                    </span>
                  ) : (
                    'Pause'
                  )
                }
                labelRight={
                  ControlStore.preventSpending === false ? (
                    <span style={{ color: '#1c802b', fontWeight: 600 }}>
                      Activated
                    </span>
                  ) : (
                    'Activate'
                  )
                }
                disabled={!!ControlStore.isLoading}
                checked={ControlStore.preventSpending === false}
                onChange={toggle}
              />
            </div>
          </MDBCol>
          <MDBCol size="12" sm="6" className="switch-wrapper">
            Paused spending prevents a wallet from spending or transferring
            BUCK$.
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBAlert>
  )
}

export default observer(ToggleSpending)
