import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import UserService from './../../../../shared/services/User.service'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import { AvClientGroupFactory } from './../../../../shared/factories'
import moment from 'moment'
import {
  UIInput,
  // UIDatePickerInput,
  // UIDropdown,
  UITypeAhead,
} from './../../../../components/forms/form-fields'
import ReferralPartnerInput from './../ReferralPartnerInput/ReferralPartnerInput.component'
import ReferralAdmin from '../ReferralAdmin/ReferralAdmin.component'
import SalesTrackerStore from './../../store'
import LoadingSpinner from './../../../../components/shared/LoadingSpinner.component'
import { MDBCollapse, MDBCol, MDBIcon, MDBBtn, MDBBtnGroup } from 'mdbreact'
import appConstants from './../../../../constants/appConstants'
import { toast } from 'react-toastify'
import preventInputUnicodeCharacters from '../../../../shared/utilities/preventInputUnicodeCharacters.function'

import './ClientGroupManager.component.scss'

const ClientGroupManager = () => {
  const { Policy, fetchClientGroups, ClientGroups } = SalesTrackerStore,
    [name, setName] = useState(''),
    [isCreating, setIsCreating] = useState(false),
    userId =
      (Policy && Policy?.get('user_id')) || UserProfileService.getUserId(),
    isSelected =
      !!Policy?.get('client_group_id') && !isNaN(Policy?.get('client_group_id'))

  let clientGroupOpts = (ClientGroups || []).map((L) => {
    const clientGroupId = SalesTrackerStore.Policy.get('client_group_id'),
      checked = `${clientGroupId}` === `${L.id()}`

    return {
      value: `${L.id()}`,
      text: L.get('group_name'),
      checked,
    }
  })

  const saveNewGroup = async () => {
    if (name) {
      const ClientGroup = await AvClientGroupFactory.create({
        user_id: userId,
        group_name: name,
      })
      await ClientGroup.save()

      if (
        ClientGroup?.id() &&
        !(SalesTrackerStore.ClientGroups || []).filter(
          (CG) => `${CG.id()}` === `${ClientGroup.id()}`
        ).length
      )
        SalesTrackerStore.ClientGroups.push(ClientGroup)
      clientGroupOpts = clientGroupOpts.map((o) => {
        o.checked = `${o.value}` === `${ClientGroup.id()}`
        return o
      })

      setIsCreating(false)
      SalesTrackerStore.setClientGroup(ClientGroup)
      setName('')
      return ClientGroup
    }

    SalesTrackerStore.setClientGroup(null)
  }

  useEffect(() => {
    fetchClientGroups(userId)
  }, [userId])

  return (
    <div
      id="ClientGroupManagerComponent"
      className={`${isSelected ? 'is-selected' : ''}`}
    >
      <div
        className={`manager-wrapper ${isCreating ? 'creating' : 'searching'}`}
      >
        <div className="group-selector">
          <UITypeAhead
            placeholder="Search Groups"
            onSelect={(value) => {
              SalesTrackerStore.setClientGroup(
                value?.value &&
                  SalesTrackerStore.ClientGroups.filter(
                    (CG) => `${CG.id()}` === `${value?.value}`
                  ).shift()
              )
            }}
            options={clientGroupOpts}
            required={true}
            rules={{ required: true }}
          ></UITypeAhead>
        </div>
        <div className="group-creator">
          <UIInput
            onChange={(value) => {
              const filteredResult = preventInputUnicodeCharacters(
                `${value?.target?.value || ''}`
              )

              if (filteredResult.hasIllegalCharacters)
                toast.warn(
                  `You tried to paste invalid text in Group Name, but we’ve attempted to correct it. Please review for accuracy.`,
                  { position: toast.POSITION.TOP_RIGHT, autoClose: 7000 }
                )

              if (filteredResult.fixedString !== name)
                setName(filteredResult.fixedString)
            }}
            value={name}
            required={true}
            rules={{ required: true }}
            label="Group Name"
          />
        </div>
        <div className="controls">
          <MDBBtnGroup>
            {isCreating ? (
              <>
                <MDBBtn
                  onClick={() => saveNewGroup()}
                  className="save-group-btn"
                >
                  Save&nbsp;
                  <MDBIcon far icon="plus-square" />
                </MDBBtn>
                <MDBBtn
                  onClick={() => {
                    setIsCreating(false)
                    setName('')
                  }}
                  className="clear-group-btn"
                >
                  Cancel&nbsp;
                  <MDBIcon fas icon="times" />
                </MDBBtn>
              </>
            ) : (
              <>
                <MDBBtn
                  onClick={() => setIsCreating(true)}
                  className="add-group-btn"
                >
                  New Group&nbsp;
                  <MDBIcon fas icon="plus" />
                </MDBBtn>
                {/*<MDBBtn onClick={() => SalesTrackerStore.setClientGroup(null)} className='clear-group-btn'>Clear<MDBIcon fas icon="times" /></MDBBtn>*/}
              </>
            )}
          </MDBBtnGroup>
        </div>
        {/*
         */}
      </div>
    </div>
  )
}

export default observer(ClientGroupManager)
