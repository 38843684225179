import React, { useState, useEffect } from 'react'
import LoadingSpinner from '../../shared/LoadingSpinner.component'
import UserProfileService from '../../../shared/services/UserProfile.service'
import UserService from '../../../shared/services/User.service'
import UserMetaService from '../../../shared/services/UserMeta.service'
import { b64toBlob } from '../../../shared/utilities/b64toBlob.function'
import {
  MDBFileInput,
  MDBBtn,
  MDBAlert,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBModalHeader,
  MDBModalBody,
  MDBModal,
  MDBInput,
} from 'mdbreact'
import env from '../../../environments/environment'
import { toast } from 'react-toastify'
import { allowedSizeByUploadType } from '../../../constants/allowedMediaSize'
import exampleVoidedCheck from '../../agents/AgentProfileIntake/assets/imgs/img.voided-check.png'

import './Modal.component.scss'

const Modal = ({
  isOpen,
  appendFormData,
  validationRules,

  // Resolve/reject modal promise.
  onResolve,
  onReject,

  // If true, do not close modal after save attempt.
  preventAutoClose,
  // If preventAutoClose == true, call onSuccess handler to invoke external calls.
  onSuccess,

  // Pass string or jsx.
  modalContent,
  // Options (top|bottom), only used with `modalContent` property.
  modalContentPosition,
  // Positional modal content.
  modalContentTop,
  modalContentBottom,
}) => {
  const [routingNumber, setRoutingNumber] = useState(''),
    [accountNumber, setAccountNumber] = useState(''),
    [isLoading, setIsLoading] = useState(false),
    [voidedCheckUrl, setVoidedCheckUrl] = useState(null),
    [voidedCheckId, setVoidedCheckId] = useState(null)

  useEffect(() => {
    fetchUserVoidedCheck().then((voided_check) => {
      setVoidedCheckId(voided_check?.voided_check_id)
      setVoidedCheckUrl(voided_check?.voided_check_url)
      setIsLoading(false)
    })
  }, [])

  const cancelModal = () => {
    onReject()
  }

  const toggleModal = () => {
    onResolve()
  }

  const fetchUserVoidedCheck = async () => {
    let u = null,
      path = null,
      id = null

    try {
      setIsLoading(true)
      u = (
        await UserProfileService.getUserMetas('profile---voided-check')
      ).shift()
      path = u && u?.meta_value ? u.meta_value : null
      id = u && u?.id ? u.id : null
    } catch (ex) {
      console.log('ex: ', ex)
      setIsLoading(false)
      return null
    }

    return {
      voided_check_id: id || null,
      voided_check_url: path
        ? `https://firebasestorage.googleapis.com/v0/b/${
            env.integrations.firebase.config.storageBucket
          }/o/${encodeURIComponent(path)}?alt=media`
        : null,
    }
  }

  const onSelectFile = (file) => {
    if (file.size > allowedSizeByUploadType.voided_check)
      return toast.error(
        'Selected file exceeds max file size.  Please try another file.'
      )
    const reader = new FileReader()
    reader.addEventListener('load', async () => {
      const payload = { filename: file?.name, data: reader.result }

      // Extract content-type from complete base64 payload.
      let match = payload.data.split(',')[0].match(/\w+\/\w+/)
      match = match && match.shift()

      // Extract raw base64 data w/out content-type.
      let base64 = payload.data.split(',')[1]

      // Build payload to send file to the server.
      const formData = new FormData()
      formData.append('voided_check', b64toBlob(base64, match))
      formData.append(`voided_check_filename`, file.name)

      try {
        setIsLoading(true)
        await UserService.uploadBlankCheck(
          UserProfileService.getUserId(),
          formData
        )
        fetchUserVoidedCheck().then((voided_check) => {
          setVoidedCheckId(voided_check?.voided_check_id)
          setVoidedCheckUrl(voided_check?.voided_check_url)
          setIsLoading(false)
        })

        if (preventAutoClose && typeof onSuccess === 'function') onSuccess()
      } catch (ex) {
        toast.error(`Failed to upload: ${ex.message}`)
      }
    })

    reader.readAsDataURL(file)
  }

  const numericKeypress = (event) => {
    if (event.which >= 48 && event.which <= 57) return true

    event.preventDefault()
    return false
  }

  const onCreateBlankCheck = async () => {
    if (!isNaN(routingNumber) && !isNaN(accountNumber)) {
      setIsLoading(true)
      try {
        await UserService.createBlankCheck(UserProfileService.getUserId(), {
          routing_number: routingNumber,
          account_number: accountNumber,
        })
        fetchUserVoidedCheck()
          .then((voided_check) => {
            setVoidedCheckId(voided_check?.voided_check_id)
            setVoidedCheckUrl(voided_check?.voided_check_url)
            setIsLoading(false)
          })
          .catch(() => setIsLoading(false))

        if (preventAutoClose && typeof onSuccess === 'function') onSuccess()
      } catch (ex) {
        console.error('Failed to upload: ', ex)
        setIsLoading(false)
      }
    }
  }

  const deleteVoidedCheck = async () => {
    if (voidedCheckId) {
      setIsLoading(true)
      try {
        await UserMetaService.delete(voidedCheckId)
        setIsLoading(false)
        setVoidedCheckId(null)
        setVoidedCheckUrl(null)
        setRoutingNumber('')
        setAccountNumber('')
      } catch (ex) {
        console.log({ ex })
        setIsLoading(false)
      }
    }
  }

  const showLoadingSpinner = () => {
    if (isLoading)
      return (
        <div className="loading-wrapper">
          <LoadingSpinner size="md" isActive={true} />
        </div>
      )
  }

  const getAlertColor = () => {
    if (isLoading) return 'info'
    return 'secondary'
  }

  const showTopModalContent = () => {
    if (modalContentTop) return modalContentTop

    if (
      modalContent &&
      (!modalContentPosition || modalContentPosition === 'top')
    )
      return modalContent
  }

  const showBottomModalContent = () => {
    if (modalContentBottom) return modalContentBottom

    if (
      modalContent &&
      (!modalContentPosition || modalContentPosition === 'bottom')
    )
      return modalContent
  }

  const renderVoidedCheck = () => {
    if (!voidedCheckUrl)
      return (
        <>
          <div className="no-check">Not Yet Uploaded</div>
        </>
      )

    return (
      <>
        {voidedCheckId ? (
          <div onClick={deleteVoidedCheck} className="delete-btn">
            <MDBIcon icon="trash" className="remove" />
          </div>
        ) : (
          <></>
        )}
        <img src={voidedCheckUrl} alt="Agent's Blank Voided Check" />
      </>
    )
  }

  const showInput = () => {
    return (
      <>
        <MDBRow className="blank-check-input-wrapper">
          <MDBCol size="12" md="6" className="voided-check-disclaimer">
            <small>
              <strong>* Agent has to update carriers themselves.</strong>
            </small>
          </MDBCol>
          <MDBCol size="12" md="6">
            <MDBFileInput
              name="voided_check"
              accept="image/*"
              getValue={(value) => onSelectFile(value[0])}
            />
          </MDBCol>
          <MDBCol size="12">
            <hr />
          </MDBCol>
          <MDBCol size="12" md="6">
            <div className="npn-text">
              Your blank check is used for automated commission payments.
            </div>
          </MDBCol>
          <MDBCol size="12" md="6"></MDBCol>
          <MDBCol size="12" md="6" className="mb-3">
            <strong>Example: </strong>
            <div className="voided-check-img-wrapper">
              <img src={exampleVoidedCheck} alt="Example voided check" />
            </div>
          </MDBCol>
          <MDBCol size="12" md="6">
            <strong>Your Voided Check: </strong>
            <div className="voided-check-img-wrapper">
              {renderVoidedCheck()}
            </div>
            {voidedCheckUrl ? (
              <></>
            ) : (
              <>
                <strong>Don't have checks? Create a voided check: </strong>
                <div className="create-check-wrapper">
                  <MDBRow>
                    <MDBCol size="12" md="9">
                      <MDBInput
                        label="Routing Number"
                        type="text"
                        name="routing_number"
                        value={routingNumber}
                        onChange={(e) => setRoutingNumber(e.target.value)}
                        onKeyPress={numericKeypress}
                        required
                        validate
                      />
                      <MDBInput
                        label="Account Number"
                        type="text"
                        name="account_number"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        onKeyPress={numericKeypress}
                        required
                        validate
                      />
                    </MDBCol>
                    <MDBCol size="12" mdmd="3">
                      <MDBBtn
                        disabled={isLoading || !routingNumber || !accountNumber}
                        onClick={onCreateBlankCheck}
                        block
                      >
                        {isLoading ? 'Creating ... ' : 'Create Voided Check'}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </div>
              </>
            )}
          </MDBCol>
          <hr />
        </MDBRow>
      </>
    )
  }

  return (
    <div className="UpdateVoidedCheckModalComponent">
      <MDBModal size="lg" isOpen={isOpen} toggle={cancelModal}>
        <MDBModalHeader toggle={cancelModal}>
          Update Voided Check
        </MDBModalHeader>
        <MDBModalBody className={isLoading ? 'is-uploading' : ''}>
          <form>
            {showLoadingSpinner()}
            <MDBAlert color={getAlertColor()}>
              {showTopModalContent()}
              {showInput()}
              {showBottomModalContent()}
            </MDBAlert>
            <MDBBtn
              color="warning"
              size={'sm'}
              block
              disabled={isLoading}
              onClick={toggleModal}
              type="button"
              className="mum-btn"
            >
              {voidedCheckUrl || preventAutoClose === true ? 'Close' : 'Cancel'}
            </MDBBtn>
          </form>
        </MDBModalBody>
      </MDBModal>
    </div>
  )
}

export default Modal
