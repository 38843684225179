import React, { useState, useEffect } from 'react'
import LoadingSpinner from '../../shared/LoadingSpinner.component'
import StateLicenseRow from '../../agents/StateLicenseRow/StateLicenseRow.component'
import {
  MDBBtn,
  MDBAlert,
  MDBModalHeader,
  MDBModalBody,
  MDBModal,
} from 'mdbreact'

import './Modal.component.scss'

const Modal = ({
  isOpen,
  appendFormData,
  validationRules,

  // Resolve/reject modal promise.
  onResolve,
  onReject,

  // If true, do not close modal after save attempt.
  preventAutoClose,
  // If preventAutoClose == true, call onSuccess handler to invoke external calls.
  onSuccess,

  // Pass string or jsx.
  modalContent,
  // Options (top|bottom), only used with `modalContent` property.
  modalContentPosition,
  // Positional modal content.
  modalContentTop,
  modalContentBottom,
}) => {
  const [isLoading, setIsLoading] = useState(false),
    [voidedCheckUrl, setVoidedCheckUrl] = useState(null)

  useEffect(() => {

  }, [])

  const cancelModal = () => {
    if (preventAutoClose && typeof onSuccess === 'function') onSuccess()
    onReject()
  }

  const toggleModal = () => {
    if (preventAutoClose && typeof onSuccess === 'function') onSuccess()
    onResolve()
  }

  const showLoadingSpinner = () => {
    if (isLoading)
      return (
        <div className="loading-wrapper">
          <LoadingSpinner size="md" isActive={true} />
        </div>
      )
  }

  const getAlertColor = () => {
    if (isLoading) return 'info'
    return 'secondary'
  }

  const showTopModalContent = () => {
    if (modalContentTop) return modalContentTop

    if (
      modalContent &&
      (!modalContentPosition || modalContentPosition === 'top')
    )
      return modalContent
  }

  const showBottomModalContent = () => {
    if (modalContentBottom) return modalContentBottom

    if (
      modalContent &&
      (!modalContentPosition || modalContentPosition === 'bottom')
    )
      return modalContent
  }

  const showInput = () => {
    return (
      <>
        <StateLicenseRow isUploader={true} source={true}></StateLicenseRow>
      </>
    )
  }

  return (
    <div className="UpdateStateLicenseModalComponent">
      <MDBModal size="lg" isOpen={isOpen} toggle={cancelModal}>
        <MDBModalHeader toggle={cancelModal}>
          Update State License
        </MDBModalHeader>
        <MDBModalBody className={isLoading ? 'is-uploading' : ''}>
          <form>
            {showLoadingSpinner()}
            <MDBAlert color={getAlertColor()}>
              {showTopModalContent()}
              {showInput()}
              {showBottomModalContent()}
            </MDBAlert>
            <MDBBtn
              color="warning"
              size={'sm'}
              block
              disabled={isLoading}
              onClick={toggleModal}
              type="button"
              className="mum-btn"
            >
              {preventAutoClose === true ? 'Close' : 'Cancel'}
            </MDBBtn>
          </form>
        </MDBModalBody>
      </MDBModal>
    </div>
  )
}

export default Modal
