import React from 'react'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBBtnGroup,
  MDBCollapse,
} from 'mdbreact'
import { addWeeks, startOfWeek, format, getQuarter, getISOWeek } from 'date-fns'
import {
  UIDatePickerInput,
  UIInput,
} from './../../../../components/forms/form-fields'
import DateManager from '../../../../shared/utilities/DateManager.class'
import ToggleAvAca from './ToggleAvAca.component'
import ProductionWidget from './ProductionWidget.component'
import ComparativeDateCalculator from './ComparativeDateCalculator.class'
import UserProfileService from '../../../../shared/services/UserProfile.service'
import UsertypeService from '../../../../shared/services/Usertype.service'
import { UserFactory } from '../../../../shared/factories'

import './AgentWidget.scss'

let timerInt = null

class AgentSelectorStore {
  searchTerm = ''
  selectedDownline = ''
  isOpen = false
  agents = []

  constructor() {
    makeAutoObservable(this)
  }

  onSearchKeyDown = (evt) => {
    if (evt.key === 'Enter' && this.searchTerm.length > 2) this.performSearch()
  }

  performSearch = async () => {
    let agents = []
    if (this.searchTerm) {
      if (
        UserProfileService.isA([
          'system-admin',
          'internal-admin',
          'internal-staff',
        ])
      ) {
        agents =
          (await UserFactory.search({
            search: { _global: this.searchTerm },
            pagination: false,
          })) || []
      } else {
        agents =
          (await UserFactory.search({
            search: {
              _global: this.searchTerm,
              signature: `%(${UserProfileService.getUserId()})%`,
            },
            pagination: false,
          })) || []
      }
    }

    this.agents = agents.sort((a, b) =>
      `${a.get('u_fname')} ${a.get('u_lname')}`.localeCompare(
        `${b.get('u_fname')} ${b.get('u_lname')}`,
        'en'
      )
    )

    this.isOpen = this.agents.length > 0

    return Promise.resolve(this.agents)
  }
}

const Store = new AgentSelectorStore()

const AgentSelectorComponent = ({ onAgentSelected }) => {
  // const isAuth = UserProfileService.isA([
  //   'system-admin',
  //   'internal-admin',
  //   'internal-staff',
  //   'district-leader',
  //   'regional-leader',
  //   'divisional-leader',
  //   'career-agent',
  // ])

  return (
    <MDBRow id="GrowthWidgetAgentSelectorComponent">
      <MDBCol size="12" md="10" style={{ position: 'relative' }}>
        <UIInput
          type="text"
          label="Search Agents"
          value={Store.searchTerm}
          onKeyDown={(evt) => Store.onSearchKeyDown(evt)}
          onChange={(e) => {
            const v = e.target.value
            Store.searchTerm = v

            if (timerInt) {
              window.clearTimeout(timerInt)
              timerInt = null
            }

            timerInt = setTimeout(() => {
              Store.performSearch()

              if (timerInt) {
                window.clearTimeout(timerInt)
                timerInt = null
              }
            }, 1000)
          }}
        />
      </MDBCol>
      <MDBCol
        size="12"
        md="2"
        style={{ position: 'relative', display: 'flex', justifyContent: 'end' }}
      >
        <MDBBtn
          disabled={Store.searchTerm === ''}
          onClick={() => {
            Store.searchTerm = ''
            Store.selectedDownline = null
            Store.agents = []
            Store.isOpen = false
            onAgentSelected(false)
          }}
          color="secondary"
          style={{ display: 'flex' }}
        >
          <span>
            <MDBIcon icon="times" />
          </span>
          &nbsp;<span>Reset</span>
        </MDBBtn>
      </MDBCol>
      <div className="dropdown-wrapper">
        <div className="dropdown-inner">
          <MDBCollapse isOpen={Store.isOpen}>
            <ul>
              {Store.agents.map((agent, idx) => {
                return (
                  <li
                    key={`agent-selectable--${agent.id()}-${idx}`}
                    onClick={() => {
                      Store.searchTerm = [
                        agent.get('u_fname'),
                        agent.get('u_lname'),
                      ]
                        .join(' ')
                        .trim()
                      Store.isOpen = false
                      onAgentSelected(agent)
                    }}
                  >
                    <strong>
                      {agent.get('u_fname') + ' ' + agent.get('u_lname') + ' '}
                    </strong>
                    <br />
                    <small>{agent.get('u_email')} </small>|
                    <small>
                      {' '}
                      {UsertypeService.idToName(agent.get('usertype_id'))}
                    </small>
                  </li>
                )
              })}
            </ul>
          </MDBCollapse>
        </div>
      </div>
    </MDBRow>
  )
}

const AgentSelector = observer(AgentSelectorComponent)

class AgentWidget extends ProductionWidget {
  Calc = null

  state = {
    reportDate: new Date(),
    metric: 'points',
    data: {},
    prevYear: new Date().getFullYear() - 1,
    diffPrevYear: 1,
    fetching: true,
    error: null,
  }

  componentDidMount() {
    this._searchMethod = 'searchAgent'
    this.Calc = new ComparativeDateCalculator()
    this._fetch()
  }

  render() {
    const { fetching } = this.state

    return (
      <MDBContainer
        className="ProductionWidgetComponent"
        id="AgentWidgetComponent"
        fluid
      >
        <MDBRow>
          <MDBCol size="12">
            <AgentSelector
              onAgentSelected={(agent) => {
                if (agent) {
                  this._searchId = agent.id()
                  this._fetchByDownline()
                } else if (agent === false) {
                  this._searchId = ''
                  this._fetch()
                }
              }}
            />
          </MDBCol>
          <MDBCol size="12">
            <ul className="source-buttons">
              <li>
                <MDBBtn
                  disabled={fetching || this.state.metric === 'points'}
                  onClick={() => {
                    this.setState({ metric: 'points' })
                  }}
                  color={
                    this.state.metric !== 'points' ? 'indigo' : 'blue-grey'
                  }
                >
                  <MDBIcon icon="check" />
                  &nbsp;View Points
                </MDBBtn>
              </li>
              <li>
                <MDBBtn
                  disabled={
                    fetching || ['av', 'av_no_aca'].includes(this.state.metric)
                  }
                  onClick={() => {
                    this.setState({ metric: 'av' })
                  }}
                  color={
                    !['av', 'av_no_aca'].includes(this.state.metric)
                      ? 'indigo'
                      : 'blue-grey'
                  }
                >
                  <MDBIcon icon="check" />
                  &nbsp;View AV
                </MDBBtn>
              </li>
              <li>
                <MDBBtn
                  disabled={fetching || this.state.metric === 'aca_lives'}
                  onClick={() => {
                    this.setState({ metric: 'aca_lives' })
                  }}
                  color={
                    this.state.metric !== 'aca_lives' ? 'indigo' : 'blue-grey'
                  }
                >
                  <MDBIcon icon="check" />
                  &nbsp;View ACA Lives
                </MDBBtn>
              </li>
              <li>
                <MDBBtn
                  disabled={fetching || this.state.metric === 'ma_lives'}
                  onClick={() => {
                    this.setState({ metric: 'ma_lives' })
                  }}
                  color={
                    this.state.metric !== 'ma_lives' ? 'indigo' : 'blue-grey'
                  }
                >
                  <MDBIcon icon="check" />
                  &nbsp;View Med Advantange
                </MDBBtn>
              </li>
              <li>
                <MDBBtn
                  disabled={fetching || this.state.metric === 'life_av'}
                  onClick={() => {
                    this.setState({ metric: 'life_av' })
                  }}
                  color={
                    this.state.metric !== 'life_av' ? 'indigo' : 'blue-grey'
                  }
                >
                  <MDBIcon icon="check" />
                  &nbsp;View Life
                </MDBBtn>
              </li>
            </ul>
          </MDBCol>
          <MDBCol size="12">
            <ToggleAvAca
              isOpen={['av', 'av_no_aca'].includes(this.state.metric)}
              isLoading={fetching}
              value={this.state.metric === 'av_no_aca'}
              onChange={(removeAcaAv) =>
                this.setState({ metric: removeAcaAv ? 'av_no_aca' : 'av' })
              }
            />
          </MDBCol>
        </MDBRow>
        <hr />
        <MDBRow>
          <MDBCol size="12">
            <MDBContainer fluid>
              <MDBRow className="widget-date-selector">
                <MDBCol size="12" sm="12" md="4" className="wds-input">
                  <UIDatePickerInput
                    label="Report Date"
                    name="report_date"
                    id="dpw_report_date"
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    selected={this.state.reportDate}
                    onChange={(date) => this._onDateChange(date?.target?.value)}
                  />
                </MDBCol>
                <MDBCol size="12" sm="12" md="8" className="wds-preset">
                  <MDBBtnGroup>
                    <MDBBtn
                      disabled={fetching}
                      onClick={() =>
                        this._onDateChange(
                          DateManager.getDayInPast('Sun', this.state.reportDate)
                        )
                      }
                      color={
                        this.state.metric !== 'points' ? 'indigo' : 'blue-grey'
                      }
                    >
                      <div>
                        <MDBIcon icon="arrow-left" />
                      </div>
                      &nbsp;<div>Previous Week</div>
                    </MDBBtn>
                    <MDBBtn
                      disabled={fetching}
                      onClick={() => this._onDateChange(new Date())}
                      color={
                        this.state.metric !== 'points' ? 'indigo' : 'blue-grey'
                      }
                    >
                      <div>Current Week</div>
                    </MDBBtn>
                    <MDBBtn
                      disabled={fetching || !this._canAdvanceDate()}
                      onClick={() =>
                        this._onDateChange(
                          DateManager.getDayInPast(
                            'Sun',
                            addWeeks(this.state.reportDate, 2)
                          )
                        )
                      }
                      color={
                        this.state.metric !== 'points' ? 'indigo' : 'blue-grey'
                      }
                    >
                      <div>Next Week</div>
                      &nbsp;
                      <div>
                        <MDBIcon icon="arrow-right" />
                      </div>
                    </MDBBtn>
                  </MDBBtnGroup>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12" sm="12" md="4">
            <MDBCard>
              <MDBCardHeader color="default-color">Current Year</MDBCardHeader>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol size="12">
                    <h5 className="float-left">
                      Week #{' '}
                      {getISOWeek(
                        startOfWeek(this.state.reportDate, { weekStartsOn: 1 })
                      )}
                    </h5>
                    <h5 className="float-right">
                      {!fetching
                        ? this._formatMetric(
                            this.state.data?.current?.wtd[this.state.metric] ||
                              0
                          )
                        : '-'}
                    </h5>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="12">
                    <h5 className="float-left">
                      Month - {format(this.state.reportDate, 'LLLL')}
                    </h5>
                    <h5 className="float-right">
                      {!fetching
                        ? this._formatMetric(
                            this.state.data?.current?.mtd[this.state.metric] ||
                              0
                          )
                        : '-'}
                    </h5>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="12">
                    <h5 className="float-left">
                      Quarter {getQuarter(this.state.reportDate)}
                    </h5>
                    <h5 className="float-right">
                      {!fetching
                        ? this._formatMetric(
                            this.state.data?.current?.qtd[this.state.metric] ||
                              0
                          )
                        : '-'}
                    </h5>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="12">
                    <h5 className="float-left">
                      Year {format(this.state.reportDate, 'yyyy')}
                    </h5>
                    <h5 className="float-right">
                      {!fetching
                        ? this._formatMetric(
                            this.state.data?.current?.ytd[this.state.metric] ||
                              0
                          )
                        : '-'}
                    </h5>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol size="12" sm="12" md="4">
            <MDBCard>
              <MDBCardHeader color="primary-color" className="prev-year">
                Previous Year{' '}
                <span className="ml-3">
                  {this.state.fetching ? (
                    <i className="fa fa-spin fa-spinner"></i>
                  ) : (
                    this._renderPrevYearDropDown()
                  )}
                </span>
              </MDBCardHeader>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol size="12">
                    <h5 className="float-left">
                      Week #{' '}
                      {getISOWeek(
                        startOfWeek(this.state.reportDate, { weekStartsOn: 1 })
                      )}
                    </h5>
                    <h5 className="float-right">
                      {!fetching
                        ? this._formatMetric(
                            this.state.data?.previous?.wtd[this.state.metric] ||
                              0
                          )
                        : '-'}
                    </h5>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="12">
                    <h5 className="float-left">
                      Month - {format(this.state.reportDate, 'LLLL')}
                    </h5>
                    <h5 className="float-right">
                      {!fetching
                        ? this._formatMetric(
                            this.state.data?.previous?.mtd[this.state.metric] ||
                              0
                          )
                        : '-'}
                    </h5>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="12">
                    <h5 className="float-left">
                      Quarter {getQuarter(this.state.reportDate)}
                    </h5>
                    <h5 className="float-right">
                      {!fetching
                        ? this._formatMetric(
                            this.state.data?.previous?.qtd[this.state.metric] ||
                              0
                          )
                        : '-'}
                    </h5>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="12">
                    <h5 className="float-left">Year {this.state.prevYear}</h5>
                    <h5 className="float-right">
                      {!fetching
                        ? this._formatMetric(
                            this.state.data?.previous?.ytd[this.state.metric] ||
                              0
                          )
                        : '-'}
                    </h5>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol size="12" sm="12" md="4">
            <MDBCard>
              <MDBCardHeader color="secondary-color">
                % Difference
              </MDBCardHeader>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol size="12">
                    <h5 className="text--center">
                      {!fetching
                        ? (
                            this.state.data?.growth?.wtd[this.state.metric] *
                            100
                          ).toFixed(2) + '%'
                        : '-'}
                    </h5>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="12">
                    <h5 className="text--center">
                      {!fetching
                        ? (
                            this.state.data?.growth?.mtd[this.state.metric] *
                            100
                          ).toFixed(2) + '%'
                        : '-'}
                    </h5>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="12">
                    <h5 className="text--center">
                      {!fetching
                        ? (
                            this.state.data?.growth?.qtd[this.state.metric] *
                            100
                          ).toFixed(2) + '%'
                        : '-'}
                    </h5>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="12">
                    <h5 className="text--center">
                      {!fetching
                        ? (
                            this.state.data?.growth?.ytd[this.state.metric] *
                            100
                          ).toFixed(2) + '%'
                        : '-'}
                    </h5>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default AgentWidget
