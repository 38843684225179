import BaseModelFactory from './base.factory'
import AvClientGroupService from './../services/AvClientGroup.service'
import AvClientGroup from './../models/av-client-group.model'

class AvClientGroupFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: AvClientGroupService, Model: AvClientGroup }
    )
  static findById = async (id) =>
    await this._findBy(
      { id: id },
      { Service: AvClientGroupService, Model: AvClientGroup }
    )
  static findAllByUserId = async (userId) =>
    await this._findAllBy(
      { user_id: userId },
      { Service: AvClientGroupService, Model: AvClientGroup }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: AvClientGroupService,
      Model: AvClientGroup,
    })
}

export default AvClientGroupFactory
