import { observer } from 'mobx-react-lite'
import React from 'react'
import ContentHtml from './../../content/ContentHtml/ContentHtml.component'
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBAlert } from 'mdbreact'
import SystemSettingStore from './../../../shared/stores/systemSetting.store'
import moment from 'moment/moment'
import { useBreakTheBarSetting } from '../useBreakTheBarSetting'

import './BreakTheBarDetails.scss'

const BreakTheBarDetails = ({ agentScope }) => {
  const { threshold, startDate } = useBreakTheBarSetting(agentScope, true)

  const getThresholdPoints = () => {
    try {
      return SystemSettingStore.Settings[
        'usertypes---break-the-bar-thresholds'
      ][agentScope]?.current
    } catch (ex) {}
  }

  const getThresholdStart = () => {
    try {
      return SystemSettingStore.Settings[
        'usertypes---break-the-bar-thresholds'
      ][agentScope]?.since
    } catch (ex) {}
  }

  const isViewingPast = () =>
    startDate &&
    getThresholdStart() &&
    moment(startDate).isBefore(moment(getThresholdStart()))

  const renderPoints = () => {
    if (getThresholdPoints())
      return (
        <div style={{ textAlign: 'center' }}>
          <small
            style={{
              color: 'rgba(255,255,255,0.5)',
              fontSize: '12px',
              display: 'block',
            }}
          >
            Current Break-the-Bar:
          </small>
          {getThresholdPoints()}&nbsp;<span className="small">Points</span>
          <br />
        </div>
      )
  }

  const renderHistoricalAlert = () => {
    if (!isNaN(threshold) && threshold > 0 && isViewingPast()) {
      return (
        <>
          <br />
          <MDBAlert color="info">
            <small>
              <strong>
                Historical: The bar was {threshold} points on Week{' '}
                {moment(startDate).week()} in {moment(startDate).year()}
              </strong>
            </small>
          </MDBAlert>
        </>
      )
    }
  }

  const renderHowTo = () => {
    if (['sig_agent', 'csr_agent', 'ba_team', 'division', 'region', 'district'].includes(agentScope)) {
      let agentType = '';

      if(['division', 'region', 'district'].includes(agentScope)) agentType = 'sig_agent';
      else agentType = agentScope;

      return (
        <ContentHtml
          contentSlug={`btb-threshold-how-to-${agentType.replace(/_/g, '-')}`}
        ></ContentHtml>
      )
    }
  }

  return (
    <MDBCard id="BreakTheBarDetails">
      <MDBCardBody>
        <MDBRow>
          <MDBCol size="12" sm="5" lg="4" className="break-the-bar-indicator">
            {renderPoints()}
            {renderHistoricalAlert()}
          </MDBCol>
          <MDBCol size="12" sm="7" lg="8" className="break-the-bar-details">
            <div className="title">
              Break The Bar
              <span className="d-xs-none d-sm-none d-md-none d-lg-inline">
                {' '}
                -- How It Works
              </span>
            </div>
            {renderHowTo()}
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}

export default observer(BreakTheBarDetails)
