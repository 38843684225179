import React from 'react'
import {
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from 'mdbreact'
import {
  UISelect,
  UIInput,
  UIInputButton,
} from './../../../../components/forms/form-fields'
import ReportsStore from './../../store/ReportsPage.store'
import TableRowFormatter from './TableRow.formatter'
import { subNavOpts, tableColumns } from './../../variables'
import LoadingSpinner from './../../../../components/shared/LoadingSpinner.component'
import { convertToCurrencyWithoutSign } from './../../../../shared/formatters/convertToCurrencyWithoutSign.formatter'
import { convertToCurrency } from './../../../../shared/formatters/convertToCurrency.formatter'
import PaginationControls from './../../../../components/content/PaginationControls/PaginationControls.component'
import appConstants from '../../../../constants/appConstants'

import './ReportsResultsTable.scss'
import UserProfileService from '../../../../shared/services/UserProfile.service'

const PER_PAGE_OPTS = [50, 100, 150, 250, 500, 1000]

class ReportsResultsTable extends React.Component {
  state = {
    tableData: {
      columns: [],
      rows: [],
      footers: [],
    },
    modals: {
      'agent-district-points': {
        show: false,
      },
      'agent-region-points': {
        show: false,
      },
      'agent-division-points': {
        show: false,
      },
      'agent-career-points': {
        show: false,
      },
    },
    search: null,
  }

  _toggleModal = (config) => {
    this.setState((state) => {
      state.modals[config.modal_name] = {
        ...state.modals[config.modal_name],
        ...config,
        show: true,
      }

      config = { ...this.props.options, ...config }

      ReportsStore.fetchDrillDown(config.modal_name, config).then((results) => {
        state.modals[config.modal_name] = {
          ...state.modals[config.modal_name],
          results,
        }
        this.setState(state)
      })
      return state
    })
  }

  _closeModals = () =>
    this.setState((state) => {
      Object.keys(state.modals).forEach((modal_name) => {
        state.modals[modal_name].show = false
        if (state.modals[modal_name]?.results)
          delete state.modals[modal_name].results
      })
      return state
    })

  _getTableData = () => {
    let tableData = this.props.data || {}

    // 1. setup columns.
    tableData.columns = []
    if (tableData?.config?.category) {
      let report = tableData.config?.report
      if (
        ['downline-production', 'my-production'].includes(
          tableData.config.category
        ) &&
        report === 'production-details'
      )
        report = UserProfileService.isA(
          ['internal-admin', 'internal-staff', 'system-admin'],
          true
        )
          ? 'production-details-bst'
          : report

      tableData.columns = report
        ? tableColumns[tableData.config.category][report]
        : tableColumns[tableData.config.category]
      if (
        (Array.isArray(tableData.columns)
          ? tableData.columns.filter(Boolean)
          : false) === false
      ) {
        console.log('table.ERR: ', tableData.config?.report)
        tableData.columns = []
      } else {
        tableData.columns = Array.isArray(tableData.columns)
          ? tableData.columns.filter(Boolean)
          : []
      }
    }

    // 2. format rows.
    let formatter,
      callbacks = { view: this._toggleModal }
    if (tableData.config?.category) {
      try {
        formatter =
          subNavOpts[tableData.config.category][tableData.config?.report] ||
          subNavOpts[tableData.config.category]
      } catch (ex) {
        console.error('Failed to determine report formatter method.', {
          ex,
          subNavOpts,
          category: tableData.config?.category,
          report: tableData.config?.report,
        })
      }
    }

    // console.log(1, {tableData})
    tableData = TableRowFormatter(formatter, {
      columns: [],
      rows: [],
      callbacks,
      ...tableData,
    })
    // console.log(2, {tableData})
    tableData = tableData ? tableData : { rows: [], columns: [] }
    // 3. Append totals row, if necc.
    if (tableData?.totals && typeof tableData.totals === 'object') {
      tableData.rows.push(tableData.totals)
    } else if (Array.isArray(tableData?.rows) && tableData.rows.length > 1) {
      let totals = {}
      tableData.columns.forEach(
        (col) =>
          (totals = {
            ...totals,
            [col.field]:
              tableData.totals && tableData.totals.hasOwnProperty(col.field)
                ? tableData.totals[col.field]
                : '',
          })
      )
      if (Object.keys(totals).length) tableData.rows.push(totals)
    }

    // 4. set state.
    // this.setState({tableData});
    return tableData
  }

  _getPagination = () => {
    if (this.props?.pagination && typeof this.props.pagination === 'object')
      return this.props.pagination
    return false
  }

  _renderColumnHeader = (col) => {
    const isColSorted = col?.sort_col
        ? col.sort_col === this.props.orderByCol
        : col.field === this.props.orderByCol,
      sortDir = isColSorted && this.props.orderByDir

    const onSortUp = () =>
      this.props.onChange(
        sortDir && `${sortDir}` === 'ASC'
          ? { order_by_col: false, order_by_dir: false }
          : { order_by_col: col.sort_col || col.field, order_by_dir: 'ASC' }
      )

    const onSortDown = () =>
      this.props.onChange(
        sortDir && `${sortDir}` === 'DESC'
          ? { order_by_col: false, order_by_dir: false }
          : { order_by_col: col.sort_col || col.field, order_by_dir: 'DESC' }
      )

    return (
      <div
        className={`table-col-sorter ${col?.sort_col ? 'is-sortable' : ''} ${
          sortDir ? 'sort-' + sortDir.toLowerCase() : ''
        }`}
      >
        <label>{col.label}</label>
        <div className="sorter">
          <div onClick={() => onSortUp()} className="up">
            <MDBIcon icon="angle-up" />
          </div>
          <div onClick={() => onSortDown()} className="down">
            <MDBIcon icon="angle-down" />
          </div>
        </div>
      </div>
    )
  }

  replaceLedgerTransactionTypeSearchString = (input) => {
    // Create a case-insensitive regular expression from the words array
    const regex = new RegExp(
      appConstants.ledger_transaction_types_converted
        .map((word) => word.replace(/\s+/g, '\\s*')) // Convert spaces to match any number of spaces
        .join('|'), // Combine all patterns with 'or'
      'gi' // 'g' for global match, 'i' for case-insensitive
    )

    // Replace each match with the word without spaces
    return input.replace(regex, (match) => match.replace(/\s+/g, ''))
  }

  componentDidMount() {
    if (this.props.search) this.setState({ search: this.props.search })
  }

  render() {
    const tableData = this._getTableData(),
      pagination =
        this._getPagination() && this._getPagination().page !== false
          ? { ...this._getPagination() }
          : false

    if (pagination && !isNaN(pagination?.page)) --pagination.page
    const count =
      pagination && !isNaN(pagination?.per_page)
        ? parseInt(pagination.per_page)
        : Array.isArray(tableData.rows) && tableData.rows.length > 500
        ? tableData.rows.length > 1000
          ? 1000
          : tableData.rows.length
        : false

    const columns = []
    if (tableData.columns && Array.isArray(tableData.columns)) {
      tableData.columns.forEach((col) => {
        columns.push({
          label: this._renderColumnHeader(col),
          field: col?.field,
        })
      })
    }

    const isAcaReport = (ReportsStore.fetchParams.coverages || []).includes(
        'ACA'
      ),
      isMedAdvReport = (ReportsStore.fetchParams.coverages || []).includes(
        'MED_ADV'
      )

    return (
      <>
        <MDBContainer
          id="ReportsResultsTable"
          fluid
          className={this.props.isFetching ? 'is-fetching' : ''}
          style={{ padding: 0 }}
        >
          <div className="loading-spinner">
            <LoadingSpinner size="md" isActive={true} />
          </div>
          <MDBRow>
            <MDBCol size="12" className="table-wrapper">
              <MDBContainer fluid className="table-filters">
                <MDBRow>
                  <MDBCol size="12" md={this.props.canExport ? '5' : '6'}>
                    <UISelect
                      label="Number of Rows"
                      disabled={
                        !!this.props.isFetching || this.props.isExporting
                      }
                      options={PER_PAGE_OPTS.concat([parseInt(count)])
                        .filter((n) => n && !isNaN(n))
                        .filter((val, idx, self) => self.indexOf(val) === idx)
                        .map((entry) => ({
                          value: `${entry}`,
                          text: `${entry}`,
                          checked:
                            pagination &&
                            pagination?.per_page &&
                            parseInt(pagination.per_page) === parseInt(entry),
                        }))}
                      search
                      name="per_page"
                      value={
                        this.props.pagination && this.props.pagination?.per_page
                      }
                      getValue={(v) => {
                        v = Array.isArray(v) ? v.shift() : v
                        if (!v) return
                        this.props.onChange({ per_page: v })
                      }}
                    />
                  </MDBCol>
                  <MDBCol
                    size={this.props.canExport ? '8' : '12'}
                    md={this.props.canExport ? '5' : '6'}
                  >
                    <UIInputButton
                      input={
                        <UIInput
                          disabled={
                            !!this.props.isFetching || this.props.isExporting
                          }
                          name="report_search"
                          label="Search Report"
                          value={this.state.search}
                          onChange={(e) =>
                            this.setState({ search: e.target.value })
                          }
                          onKeyPress={(evt) => {
                            let submit = false
                            try {
                              submit = `${evt.key}`.toLowerCase() === 'enter'
                            } catch (ex) {}

                            if (
                              submit &&
                              this.state.search &&
                              `${this.state.search}`.length >= 3
                            )
                              this.props.onChange({
                                search:
                                  this.replaceLedgerTransactionTypeSearchString(
                                    this.state.search
                                  ),
                                page: 1,
                              })
                            else if (
                              submit &&
                              (!this.state.search ||
                                `${this.state.search}`.length === 0)
                            )
                              this.props.onChange({
                                search:
                                  this.replaceLedgerTransactionTypeSearchString(
                                    this.state.search
                                  ),
                              })
                          }}
                        />
                      }
                      button={
                        <MDBBtn
                          disabled={
                            (this.state.search &&
                              `${this.state.search}`.length < 3) ||
                            !!this.props.isFetching ||
                            this.props.isExporting
                          }
                          onClick={() => {
                            if (
                              this.state.search &&
                              `${this.state.search}`.length >= 3
                            )
                              this.props.onChange({
                                search:
                                  this.replaceLedgerTransactionTypeSearchString(
                                    this.state.search
                                  ),
                              })
                            else if (
                              !this.state.search ||
                              `${this.state.search}`.length === 0
                            )
                              this.props.onChange({
                                search:
                                  this.replaceLedgerTransactionTypeSearchString(
                                    this.state.search
                                  ),
                              })
                          }}
                          className="btn btn-sm"
                        >
                          <MDBIcon icon="search" />
                        </MDBBtn>
                      }
                      btnPosition={'right'}
                    />
                  </MDBCol>
                  {this.props.canExport ? (
                    <MDBCol size="4" md="2">
                      <MDBBtn
                        block
                        disabled={
                          !!this.props.isFetching || this.props.isExporting
                        }
                        className="btn btn-sm export-btn"
                        onClick={this.props.onExport}
                      >
                        Export Results&nbsp;
                        {this.props.isExporting ? (
                          <i className="fa fa-spin fa-spinner" />
                        ) : (
                          <MDBIcon icon="download" />
                        )}
                      </MDBBtn>
                    </MDBCol>
                  ) : (
                    <></>
                  )}
                </MDBRow>
              </MDBContainer>
              <div className="reports-table">
                <MDBTable className="text-capitalize text-truncate">
                  <MDBTableHead columns={columns}></MDBTableHead>
                  <MDBTableBody rows={tableData.rows}></MDBTableBody>
                </MDBTable>
              </div>
              {pagination && this.props.enablePagination ? (
                <MDBContainer fluid className="table-pagination">
                  <MDBRow>
                    <MDBCol size="12">
                      <PaginationControls
                        pagination={pagination}
                        onPageSelect={(pagination) =>
                          this.props.onChange({
                            ...pagination,
                            page: parseInt(pagination.page) + 1,
                          })
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              ) : (
                <></>
              )}
              <MDBModal
                size={'lg'}
                isOpen={this.state.modals['agent-district-points'].show}
                toggle={this._closeModals}
              >
                <MDBModalHeader toggle={this._closeModals}>
                  Agent Totals
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBTable>
                    <MDBTableHead
                      columns={[
                        { label: 'Agent Name', field: 'av_user' },
                        // { label: 'Points (Past)', field: 'av_points_prev' },
                        { label: 'Points', field: 'av_points_curr' },
                        // { label: 'Points (Growth)', field: 'av_points_growth' },
                        // { label: 'Premium (Past)', field: 'av_premium_past' },
                        isAcaReport
                          ? { label: 'ACA Lives', field: 'av_lives_curr' }
                          : isMedAdvReport
                          ? { label: 'Med Advantage', field: 'av_med_adv_curr' }
                          : { label: 'Premium', field: 'av_premium_curr' },
                        // { label: 'Premium (Growth)', field: 'av_premium_growth' },
                      ]}
                    ></MDBTableHead>
                    <MDBTableBody
                      rows={
                        (this.state.modals['agent-district-points']?.results &&
                          this.state.modals[
                            'agent-district-points'
                          ].results.rows.map((row) => {
                            if (isAcaReport) {
                              return {
                                av_user: row.av_user,
                                // av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.av_points_prev) ? 0 : row.av_points_prev),
                                av_points_curr: convertToCurrencyWithoutSign(
                                  isNaN(row?.av_points_curr)
                                    ? 0
                                    : row.av_points_curr
                                ),
                                // av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.av_points_growth) ? 0 : row.av_points_growth)+'%',
                                // av_premium_prev: convertToCurrency(isNaN(row?.av_premium_prev) ? 0 : row.av_premium_prev),
                                av_lives_curr: parseInt(
                                  isNaN(row?.av_lives_curr)
                                    ? 0
                                    : row.av_lives_curr
                                ),
                                // av_premium_growth: convertToCurrencyWithoutSign(isNaN(row?.av_premium_growth) ? 0 : row.av_premium_growth)+'%',
                              }
                            } else if (isMedAdvReport) {
                              return {
                                av_user: row.av_user,
                                // av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.av_points_prev) ? 0 : row.av_points_prev),
                                av_points_curr: convertToCurrencyWithoutSign(
                                  isNaN(row?.av_points_curr)
                                    ? 0
                                    : row.av_points_curr
                                ),
                                // av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.av_points_growth) ? 0 : row.av_points_growth)+'%',
                                // av_premium_prev: convertToCurrency(isNaN(row?.av_premium_prev) ? 0 : row.av_premium_prev),
                                av_med_adv_curr: parseInt(
                                  isNaN(row?.av_med_adv_curr)
                                    ? 0
                                    : row.av_med_adv_curr
                                ),
                                // av_premium_growth: convertToCurrencyWithoutSign(isNaN(row?.av_premium_growth) ? 0 : row.av_premium_growth)+'%',
                              }
                            } else {
                              return {
                                av_user: row.av_user,
                                // av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.av_points_prev) ? 0 : row.av_points_prev),
                                av_points_curr: convertToCurrencyWithoutSign(
                                  isNaN(row?.av_points_curr)
                                    ? 0
                                    : row.av_points_curr
                                ),
                                // av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.av_points_growth) ? 0 : row.av_points_growth)+'%',
                                // av_premium_prev: convertToCurrency(isNaN(row?.av_premium_prev) ? 0 : row.av_premium_prev),
                                av_premium_curr: convertToCurrency(
                                  isNaN(row?.av_premium_curr)
                                    ? 0
                                    : row.av_premium_curr
                                ),
                                // av_premium_growth: convertToCurrencyWithoutSign(isNaN(row?.av_premium_growth) ? 0 : row.av_premium_growth)+'%',
                              }
                            }
                          })) ||
                        []
                      }
                    ></MDBTableBody>
                  </MDBTable>
                </MDBModalBody>
              </MDBModal>
              <MDBModal
                size={'lg'}
                isOpen={this.state.modals['agent-region-points'].show}
                toggle={this._closeModals}
              >
                <MDBModalHeader toggle={this._closeModals}>
                  Agent Totals
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBTable>
                    <MDBTableHead
                      columns={[
                        { label: 'Agent Name', field: 'av_user' },
                        // { label: 'Points (Past)', field: 'av_points_prev' },
                        { label: 'Points', field: 'av_points_curr' },
                        // { label: 'Points (Growth)', field: 'av_points_growth' },
                        // { label: 'Premium (Past)', field: 'av_premium_past' },
                        isAcaReport
                          ? { label: 'ACA Lives', field: 'av_lives_curr' }
                          : isMedAdvReport
                          ? { label: 'Med Advantage', field: 'av_med_adv_curr' }
                          : { label: 'Premium', field: 'av_premium_curr' },
                        // { label: 'Premium (Growth)', field: 'av_premium_growth' },
                      ]}
                    ></MDBTableHead>
                    <MDBTableBody
                      rows={
                        (this.state.modals['agent-region-points']?.results &&
                          this.state.modals[
                            'agent-region-points'
                          ].results.rows.map((row) => {
                            if (isAcaReport) {
                              return {
                                av_user: row.av_user,
                                // av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.av_points_prev) ? 0 : row.av_points_prev),
                                av_points_curr: convertToCurrencyWithoutSign(
                                  isNaN(row?.av_points_curr)
                                    ? 0
                                    : row.av_points_curr
                                ),
                                // av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.av_points_growth) ? 0 : row.av_points_growth)+'%',
                                // av_premium_prev: convertToCurrency(isNaN(row?.av_premium_prev) ? 0 : row.av_premium_prev),
                                av_lives_curr: parseInt(
                                  isNaN(row?.av_lives_curr)
                                    ? 0
                                    : row.av_lives_curr
                                ),
                                // av_premium_growth: convertToCurrencyWithoutSign(isNaN(row?.av_premium_growth) ? 0 : row.av_premium_growth)+'%',
                              }
                            } else if (isMedAdvReport) {
                              return {
                                av_user: row.av_user,
                                // av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.av_points_prev) ? 0 : row.av_points_prev),
                                av_points_curr: convertToCurrencyWithoutSign(
                                  isNaN(row?.av_points_curr)
                                    ? 0
                                    : row.av_points_curr
                                ),
                                // av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.av_points_growth) ? 0 : row.av_points_growth)+'%',
                                // av_premium_prev: convertToCurrency(isNaN(row?.av_premium_prev) ? 0 : row.av_premium_prev),
                                av_med_adv_curr: parseInt(
                                  isNaN(row?.av_med_adv_curr)
                                    ? 0
                                    : row.av_med_adv_curr
                                ),
                                // av_premium_growth: convertToCurrencyWithoutSign(isNaN(row?.av_premium_growth) ? 0 : row.av_premium_growth)+'%',
                              }
                            } else {
                              return {
                                av_user: row.av_user,
                                // av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.av_points_prev) ? 0 : row.av_points_prev),
                                av_points_curr: convertToCurrencyWithoutSign(
                                  isNaN(row?.av_points_curr)
                                    ? 0
                                    : row.av_points_curr
                                ),
                                // av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.av_points_growth) ? 0 : row.av_points_growth)+'%',
                                // av_premium_prev: convertToCurrency(isNaN(row?.av_premium_prev) ? 0 : row.av_premium_prev),
                                av_premium_curr: convertToCurrency(
                                  isNaN(row?.av_premium_curr)
                                    ? 0
                                    : row.av_premium_curr
                                ),
                                // av_premium_growth: convertToCurrencyWithoutSign(isNaN(row?.av_premium_growth) ? 0 : row.av_premium_growth)+'%',
                              }
                            }
                          })) ||
                        []
                      }
                    ></MDBTableBody>
                  </MDBTable>
                </MDBModalBody>
              </MDBModal>
              <MDBModal
                size={'lg'}
                isOpen={this.state.modals['agent-division-points'].show}
                toggle={this._closeModals}
              >
                <MDBModalHeader toggle={this._closeModals}>
                  Agent Totals
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBTable>
                    <MDBTableHead
                      columns={[
                        { label: 'Agent Name', field: 'av_user' },
                        // { label: 'Points (Past)', field: 'av_points_prev' },
                        { label: 'Points', field: 'av_points_curr' },
                        // { label: 'Points (Growth)', field: 'av_points_growth' },
                        // { label: 'Premium (Past)', field: 'av_premium_past' },
                        isAcaReport
                          ? { label: 'ACA Lives', field: 'av_lives_curr' }
                          : isMedAdvReport
                          ? { label: 'Med Advantage', field: 'av_med_adv_curr' }
                          : { label: 'Premium', field: 'av_premium_curr' },
                        // { label: 'Premium (Growth)', field: 'av_premium_growth' },
                      ]}
                    ></MDBTableHead>
                    <MDBTableBody
                      rows={
                        (this.state.modals['agent-division-points']?.results &&
                          this.state.modals[
                            'agent-division-points'
                          ].results.rows.map((row) => {
                            if (isAcaReport) {
                              return {
                                av_user: row.av_user,
                                // av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.av_points_prev) ? 0 : row.av_points_prev),
                                av_points_curr: convertToCurrencyWithoutSign(
                                  isNaN(row?.av_points_curr)
                                    ? 0
                                    : row.av_points_curr
                                ),
                                // av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.av_points_growth) ? 0 : row.av_points_growth)+'%',
                                // av_premium_prev: convertToCurrency(isNaN(row?.av_premium_prev) ? 0 : row.av_premium_prev),
                                av_lives_curr: parseInt(
                                  isNaN(row?.av_lives_curr)
                                    ? 0
                                    : row.av_lives_curr
                                ),
                                // av_premium_growth: convertToCurrencyWithoutSign(isNaN(row?.av_premium_growth) ? 0 : row.av_premium_growth)+'%',
                              }
                            } else if (isMedAdvReport) {
                              return {
                                av_user: row.av_user,
                                // av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.av_points_prev) ? 0 : row.av_points_prev),
                                av_points_curr: convertToCurrencyWithoutSign(
                                  isNaN(row?.av_points_curr)
                                    ? 0
                                    : row.av_points_curr
                                ),
                                // av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.av_points_growth) ? 0 : row.av_points_growth)+'%',
                                // av_premium_prev: convertToCurrency(isNaN(row?.av_premium_prev) ? 0 : row.av_premium_prev),
                                av_med_adv_curr: parseInt(
                                  isNaN(row?.av_med_adv_curr)
                                    ? 0
                                    : row.av_med_adv_curr
                                ),
                                // av_premium_growth: convertToCurrencyWithoutSign(isNaN(row?.av_premium_growth) ? 0 : row.av_premium_growth)+'%',
                              }
                            } else {
                              return {
                                av_user: row.av_user,
                                // av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.av_points_prev) ? 0 : row.av_points_prev),
                                av_points_curr: convertToCurrencyWithoutSign(
                                  isNaN(row?.av_points_curr)
                                    ? 0
                                    : row.av_points_curr
                                ),
                                // av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.av_points_growth) ? 0 : row.av_points_growth)+'%',
                                // av_premium_prev: convertToCurrency(isNaN(row?.av_premium_prev) ? 0 : row.av_premium_prev),
                                av_premium_curr: convertToCurrency(
                                  isNaN(row?.av_premium_curr)
                                    ? 0
                                    : row.av_premium_curr
                                ),
                                // av_premium_growth: convertToCurrencyWithoutSign(isNaN(row?.av_premium_growth) ? 0 : row.av_premium_growth)+'%',
                              }
                            }
                          })) ||
                        []
                      }
                    ></MDBTableBody>
                  </MDBTable>
                </MDBModalBody>
              </MDBModal>
              <MDBModal
                size={'lg'}
                isOpen={this.state.modals['agent-career-points'].show}
                toggle={this._closeModals}
              >
                <MDBModalHeader toggle={this._closeModals}>
                  Agent Totals
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBTable>
                    <MDBTableHead
                      columns={[
                        { label: 'Agent Name', field: 'av_user' },
                        // { label: 'Points (Past)', field: 'av_points_prev' },
                        { label: 'Points', field: 'av_points_curr' },
                        // { label: 'Points (Growth)', field: 'av_points_growth' },
                        // { label: 'Premium (Past)', field: 'av_premium_past' },
                        isAcaReport
                          ? { label: 'ACA Lives', field: 'av_lives_curr' }
                          : isMedAdvReport
                          ? { label: 'Med Advantage', field: 'av_med_adv_curr' }
                          : { label: 'Premium', field: 'av_premium_curr' },
                        // { label: 'Premium (Growth)', field: 'av_premium_growth' },
                      ]}
                    ></MDBTableHead>
                    <MDBTableBody
                      rows={
                        (this.state.modals['agent-career-points']?.results &&
                          this.state.modals[
                            'agent-career-points'
                          ].results.rows.map((row) => {
                            if (isAcaReport) {
                              return {
                                av_user: row.av_user,
                                // av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.av_points_prev) ? 0 : row.av_points_prev),
                                av_points_curr: convertToCurrencyWithoutSign(
                                  isNaN(row?.av_points_curr)
                                    ? 0
                                    : row.av_points_curr
                                ),
                                // av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.av_points_growth) ? 0 : row.av_points_growth)+'%',
                                // av_premium_prev: convertToCurrency(isNaN(row?.av_premium_prev) ? 0 : row.av_premium_prev),
                                av_lives_curr: parseInt(
                                  isNaN(row?.av_lives_curr)
                                    ? 0
                                    : row.av_lives_curr
                                ),
                                // av_premium_growth: convertToCurrencyWithoutSign(isNaN(row?.av_premium_growth) ? 0 : row.av_premium_growth)+'%',
                              }
                            } else if (isMedAdvReport) {
                              return {
                                av_user: row.av_user,
                                // av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.av_points_prev) ? 0 : row.av_points_prev),
                                av_points_curr: convertToCurrencyWithoutSign(
                                  isNaN(row?.av_points_curr)
                                    ? 0
                                    : row.av_points_curr
                                ),
                                // av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.av_points_growth) ? 0 : row.av_points_growth)+'%',
                                // av_premium_prev: convertToCurrency(isNaN(row?.av_premium_prev) ? 0 : row.av_premium_prev),
                                av_med_adv_curr: parseInt(
                                  isNaN(row?.av_med_adv_curr)
                                    ? 0
                                    : row.av_med_adv_curr
                                ),
                                // av_premium_growth: convertToCurrencyWithoutSign(isNaN(row?.av_premium_growth) ? 0 : row.av_premium_growth)+'%',
                              }
                            } else {
                              return {
                                av_user: row.av_user,
                                // av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.av_points_prev) ? 0 : row.av_points_prev),
                                av_points_curr: convertToCurrencyWithoutSign(
                                  isNaN(row?.av_points_curr)
                                    ? 0
                                    : row.av_points_curr
                                ),
                                // av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.av_points_growth) ? 0 : row.av_points_growth)+'%',
                                // av_premium_prev: convertToCurrency(isNaN(row?.av_premium_prev) ? 0 : row.av_premium_prev),
                                av_premium_curr: convertToCurrency(
                                  isNaN(row?.av_premium_curr)
                                    ? 0
                                    : row.av_premium_curr
                                ),
                                // av_premium_growth: convertToCurrencyWithoutSign(isNaN(row?.av_premium_growth) ? 0 : row.av_premium_growth)+'%',
                              }
                            }
                          })) ||
                        []
                      }
                    ></MDBTableBody>
                  </MDBTable>
                </MDBModalBody>
              </MDBModal>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </>
    )
  }
}

export default ReportsResultsTable
