import { makeAutoObservable } from 'mobx'
import AdminApi from './../../../api/admin-api/admin-api.js'
import { BehaviorSubject } from 'rxjs'

function translateAgentScope(agentScope) {
  switch (agentScope) {
    case 'user_id':
    case 'sig_agent':
      return 'sig_agent'
    case 'ba_team_id':
    case 'ba_team':
      return 'ba_team'
    case 'sub_agent_id':
    case 'csr_agent':
      return 'csr_agent'
    case 'division':
      return 'division'
    case 'region':
      return 'region'
    case 'district':
      return 'district'
    default:
      return 'sig_agent'
  }
}

class LeaderboardsStore {
  constructor() {
    makeAutoObservable(this)
  }

  categories = []
  fetchParams = {}

  tableData = new BehaviorSubject({ columns: [], rows: [] })
  getTableData = () => this.tableData

  isFetching = false

  fetch = async (type, start, stop, categories, agentType, outputMode) => {
    // opts = opts && typeof opts === 'object' ? opts : {};

    if (typeof type !== 'undefined') this.fetchParams.type = type

    if (typeof start !== 'undefined') this.fetchParams.start = start

    if (typeof stop !== 'undefined') this.fetchParams.stop = stop

    if (typeof categories !== 'undefined') {
      categories =
        categories && typeof categories === 'string'
          ? categories.split('-')
          : categories
      categories = (
        Array.isArray(categories) ? categories : [categories]
      ).filter((n) => !!n)
      this.fetchParams.categories = categories
    }

    if (typeof agentType !== 'undefined')
      this.fetchParams.agent_type = agentType

    if (typeof outputMode !== 'undefined')
      this.fetchParams.output_mode = outputMode

    this.isFetching = true

    let result
    try {
      result = await AdminApi.getLeaderboardData(
        this.fetchParams.type ? this.fetchParams.type : 'points',
        this.fetchParams.start,
        this.fetchParams.stop,
        this.fetchParams.categories,
        this.fetchParams.agent_type
          ? `${this.fetchParams.agent_type}`
          : 'user_id',
        this.fetchParams.output_mode,
        this.fetchParams?.opts || {}
      )
    } catch (ex) {
      this.isFetching = false
      console.error(ex)
    }

    let rows = result?.data?.payload?.rows || [],
      columns = result?.data?.payload?.columns || false

    if (columns) {
      this.tableData.next({ rows, columns })
      this.isFetching = false
      return { rows, columns, config: this.fetchParams }
    }

    this.isFetching = false
    return false
  }

  updAndFetch = async (params) => {
    if (params && typeof params === 'object') {
      Object.keys(params).forEach((key) => {
        this.fetchParams[key] = params[key]
      })
      return this.fetch()
    }
  }

  getAgentScope() {
    return translateAgentScope(this.fetchParams?.agent_type)
  }
}

export default new LeaderboardsStore()
