import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { MDBContainer, MDBBtn, MDBRow, MDBCol } from 'mdbreact'
import { months } from './../../variables'
import LeaderboardsStore from './../../store/LeaderboardsPage.store'
import DatePicker from 'react-datepicker'
import './LeaderboardDateFilter.scss'

const LeaderboardDateFilter = ({ config, setConfig }) => {
  const [customDates, setCustomDates] = useState({
    start: config.start,
    stop: config.stop,
  })

  const customDatePickerFrom = () => {
    let customDateFrom = moment(customDates.start, 'YYYY-MM-DD').toDate()
    let Start = new Date('January 1, 1900 00:00:00')
    let End = new Date()
    let yearsRange = moment(End).diff(Start, 'years')
    let years = []
    for (let year = 0; year <= yearsRange; year++)
      years.push(Start.getFullYear() + year)
    years.reverse()

    return (
      <DatePicker
        className="form-control-plaintext"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {'<'}
            </button>
            <select
              value={moment(date).format('YYYY')}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[parseInt(moment(date).format('M')) - 1]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {'>'}
            </button>
          </div>
        )}
        popperClassName="customDatepickerZIndex"
        dateFormat="MM/dd/yyyy"
        selected={customDateFrom}
        onChange={(date) =>
          setCustomDates((customDates) => ({
            ...customDates,
            start: moment(date).format('YYYY-MM-DD'),
          }))
        }
      />
    )
  }

  const customDatePickerTo = () => {
    let customDateTo = moment(customDates.stop, 'YYYY-MM-DD').toDate(),
      Start = new Date('January 1, 1900 00:00:00'),
      End = new Date(),
      yearsRange = moment(End).diff(Start, 'years'),
      years = []

    for (let year = 0; year <= yearsRange; year++)
      years.push(Start.getFullYear() + year)
    years.reverse()

    return (
      <DatePicker
        className="form-control-plaintext"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {'<'}
            </button>
            <select
              value={moment(date).format('YYYY')}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[parseInt(moment(date).format('M')) - 1]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {'>'}
            </button>
          </div>
        )}
        popperClassName="customDatepickerZIndex"
        dateFormat="MM/dd/yyyy"
        selected={customDateTo}
        onChange={(date) =>
          setCustomDates((customDates) => ({
            ...customDates,
            stop: moment(date).format('YYYY-MM-DD'),
          }))
        }
      />
    )
  }

  const fetchCustom = () => {
    if (
      new Date().getTime() <
      moment(customDates.start, 'YYYY-MM-DD').toDate().getTime()
    )
      toast.error('Start date can not be in the future.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    else if (
      moment(customDates.start, 'YYYY-MM-DD').toDate().getTime() >
      moment(customDates.stop, 'YYYY-MM-DD').toDate().getTime()
    )
      toast.error('End date can not be before start date.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    else
      setConfig({
        ...config,
        output_mode: 'custom',
        stop: customDates.stop,
        start: customDates.start,
      })
  }

  toast.configure()

  return (
    <MDBContainer id="LeaderboardDateFilter" fluid>
      <MDBRow>
        <MDBCol size="12">
          <h4>Custom Timeframe</h4>
          <form>
            <MDBRow className="custom-datepicker">
              <MDBCol>
                <label className="grey-text">From</label>
                <div>{customDatePickerFrom()}</div>
              </MDBCol>
              <MDBCol>
                <label className="grey-text">To</label>
                <div>{customDatePickerTo()}</div>
              </MDBCol>
            </MDBRow>
            <MDBBtn
              disabled={LeaderboardsStore.isFetching}
              color="indigo"
              className="btn-block"
              onClick={() => fetchCustom()}
            >
              Submit
            </MDBBtn>
          </form>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(LeaderboardDateFilter)
