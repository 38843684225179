import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import {
  MDBCollapse,
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
} from 'mdbreact'
import { UIInputCheckbox } from './../../../../components/forms/form-fields'
import LeaderboardsStore from './../../store/LeaderboardsPage.store'
import './LeaderboardAvAcaToggle.scss'

const LeaderboardAvAcaToggle = ({ config, setConfig }) => {
  return (
    <MDBCollapse
      isOpen={config && config?.type === 'av'}
      id="LeaderboardAvAcaToggle"
    >
      <MDBContainer>
        <MDBRow>
          <MDBCol>
            <UIInputCheckbox
              label="Remove ACA from AV totals?"
              id={`remove_aca_av`}
              name={`remove_aca_av`}
              checked={!!config?.remove_aca_av}
              disabled={LeaderboardsStore.isFetching}
              onChange={(evt) => {
                setConfig({
                  ...config,
                  remove_aca_av: !config?.remove_aca_av,
                })
              }}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBCollapse>
  )
}

export default observer(LeaderboardAvAcaToggle)
