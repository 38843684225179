// (level_points/10000)*250 = credits
// (100000/10000)*250 = 2500
export default [
  { level: 1, min: 1000, max: 2499, credits: 50, name: 'Bronze' },
  { level: 2, min: 2500, max: 4999, credits: 100, name: 'Silver' },
  { level: 3, min: 5000, max: 7499, credits: 150, name: 'Gold' },
  { level: 4, min: 7500, max: 9999, credits: 200, name: 'Platinum' },
  { level: 5, min: 10000, max: 12499, credits: 250, name: 'Emerald' },
  { level: 6, min: 12500, max: 14999, credits: 300, name: 'Sapphire' },
  { level: 7, min: 15000, max: 19999, credits: 400, name: 'Ruby' },
  { level: 8, min: 20000, max: 29999, credits: 500, name: 'Diamond' },
  { level: 9, min: 30000, max: 39999, credits: 700, name: 'Double Diamond' },
  { level: 10, min: 40000, max: 49999, credits: 1000, name: 'Triple Diamond' },
  { level: 11, min: 50000, max: 59999, credits: 1250, name: 'Crown' },
  { level: 12, min: 60000, max: 69999, credits: 1500, name: 'Excalibur' },
  { level: 13, min: 70000, max: 79999, credits: 1750, name: 'Double Swords' },
  { level: 14, min: 80000, max: 89999, credits: 2000, name: 'Jeweled Crown' },
  { level: 15, min: 90000, max: 99999, credits: 2250, name: 'Shielded Crown' },
  {
    level: 16,
    min: 100000,
    max: 124999,
    credits: 2600,
    name: 'Hall of Fame Crown',
  },
  { level: 17, min: 125000, max: 149999, credits: 3000, name: 'Royal Robe' },
  { level: 18, min: 150000, max: 174999, credits: 3500, name: 'Royal Horse' },
  {
    level: 19,
    min: 175000,
    max: 199999,
    credits: 4000,
    name: 'Royal Carriage',
  },
  { level: 20, min: 200000, max: 224999, credits: 4500, name: 'Royal Castle' },
  { level: 21, min: 225000, max: 249999, credits: 5000, name: 'Royal Army' },
  { level: 22, min: 250000, max: 274999, credits: 5000, name: 'Dragon' },
  {
    level: 23,
    min: 275000,
    max: 299999,
    credits: 5000,
    name: 'Double Dragons',
  },
  { level: 24, min: 300000, max: 999999, credits: 5000, name: 'Iron Throne' },
]
