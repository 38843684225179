import React from 'react'
import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
} from 'mdbreact'
import UsertypeService from './../../../shared/services/Usertype.service'
import UserProfileService from './../../../shared/services/UserProfile.service'

import './ConfirmDeleteModal.scss'

class ConfirmDeleteModalComponent extends React.Component {
  state = {
    isOpen: false,
    title: '',
    body: <></>,
    isEnabled: false,
  }

  #shouldOpen = () => !!this.props.confirm

  #useDeactivation = () =>
    this.props?.isDeactivate === true || `${this.props?.isDeactivate}` === '1'

  #shouldEnable = () => {
    if (typeof this.props?.shouldEnable === 'boolean') {
      if (this.props.shouldEnable !== this.state.isEnabled)
        this.setState({
          isEnabled: this.props.shouldEnable,
          isOpen: this.props.shouldEnable
            ? this.#shouldOpen()
            : this.state.isOpen,
        })
    } else {
      let shouldEnable =
        UsertypeService.isA(
          'internal-admin',
          UserProfileService.getCurrentUserTypeId()
        ) ||
        UsertypeService.isA(
          'internal-staff',
          UserProfileService.getCurrentUserTypeId()
        ) ||
        UsertypeService.isA(
          'system-admin',
          UserProfileService.getCurrentUserTypeId()
        )

      if (shouldEnable)
        this.setState({
          isEnabled: shouldEnable,
          isOpen: shouldEnable ? this.#shouldOpen() : this.state.isOpen,
        })
    }
  }

  #toggleConfirmModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
    if (this.state.isOpen) this.props.onCancel();
  }

  #confirm = () => {
    if (typeof this.props.onConfirm === 'function') {
      this.props.onConfirm(this.props.params)
    } else {
      console.error(
        'ConfirmDeleteModal does not have a valid `onConfirm` handler.  ',
        { typeof: typeof this.props.onDelete, onConfirm: this.props.onConfirm }
      )
    }
    this.setState({ isOpen: false })
  }

  #cancel = () => {
    if (typeof this.props.onCancel === 'function') this.props.onCancel()

    this.setState({ isOpen: false })
  }

  componentDidMount() {
    this.#shouldEnable()
  }

  componentDidUpdate() {
    if (this.state.isEnabled && this.#shouldOpen() !== this.state.isOpen)
      this.setState({ isOpen: this.#shouldOpen() })
  }

  componentWillUnmount() {}

  render() {
    return (
      <>
        {this.state.isEnabled && this.props.confirm ? (
          <div className="ConfirmDeleteModal">
            <MDBModal
              size="lg"
              isOpen={this.state.isOpen}
              toggle={this.#toggleConfirmModal}
            >
              <MDBModalHeader>
                <MDBIcon icon="exclamation-triangle" />
                &nbsp;&nbsp;Are You Sure?
              </MDBModalHeader>
              <MDBModalBody>
                You have selected to{' '}
                {this.#useDeactivation() ? 'deactivate' : 'delete'} the record
                labeled:
                <br />
                <p className="confirm-record">{this.props.confirm}</p>
                {this.#useDeactivation() ? '' : 'This can not be undone.'}
              </MDBModalBody>
              <MDBModalFooter>
                <MDBRow>
                  <MDBCol size="12" md="8">
                    <MDBBtn className="btn-block" onClick={this.#confirm}>
                      Yes, {this.#useDeactivation() ? 'Deactivate' : 'Delete'}
                    </MDBBtn>
                  </MDBCol>
                  <MDBCol size="12" md="4">
                    <MDBBtn className="btn-block" onClick={this.#cancel}>
                      Cancel
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBModalFooter>
            </MDBModal>
          </div>
        ) : (
          <></>
        )}
      </>
    )
  }
}

export default ConfirmDeleteModalComponent
