import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { MDBContainer, MDBBtn, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import LeaderboardAvAcaToggle from './../LeaderboardAvAcaToggle/LeaderboardAvAcaToggle.component'
import LeaderboardsStore from './../../store/LeaderboardsPage.store'
import './LeaderboardFilterSelector.scss'

const LeaderboardFilterSelector = ({ config, setConfig }) => {
  const boardTypes = [
    { type: 'points', label: 'Points', color: 'indigo' },
    { type: 'av', label: 'Annualized Volume (AV)', color: 'indigo' },
    { type: 'lives', label: 'ACA Lives', color: 'orange' },
    { type: 'med_adv', label: 'Med Advantage', color: 'purple' },
  ]

  return (
    <MDBContainer id="LeaderboardFilterSelector" fluid>
      <MDBRow className="type-btns">
        {boardTypes
          .sort((typeA, typeB) =>
            `${typeA.label}`.localeCompare(`${typeB.label}`, 'en')
          )
          .filter(
            (boardType) => boardType.type !== LeaderboardsStore.fetchParams.type
          )
          .map((boardType, i) => (
            <MDBCol
              size="12"
              sm="4"
              key={`${boardType.type}`.replace(/_/g, '-') + '-leaderboard'}
            >
              <MDBBtn
                disabled={LeaderboardsStore.isFetching}
                onClick={() =>
                  setConfig({
                    ...config,
                    type: boardType.type,
                  })
                }
                className={`btn board-type-btn btn-block btn-${boardType.color}`}
              >
                {boardType.label}
              </MDBBtn>
            </MDBCol>
          ))}
      </MDBRow>
      <MDBRow className="filter-btns">
        <MDBCol sm="4">
          <MDBBtn
            className={
              'btn-red btn-block ' +
              (config.categories === 'HEALTH' ? ' btn-active' : '')
            }
            disabled={LeaderboardsStore.isFetching}
            onClick={() =>
              !LeaderboardsStore.isFetching &&
              setConfig({
                ...config,
                categories: config.categories === 'HEALTH' ? '' : 'HEALTH',
              })
            }
          >
            <MDBIcon icon="check" />
            HEALTH
          </MDBBtn>
        </MDBCol>
        <MDBCol sm="4">
          <MDBBtn
            className={
              'btn-green btn-block ' +
              (config.categories === 'LIFE' ? ' btn-active' : '')
            }
            disabled={LeaderboardsStore.isFetching}
            onClick={() =>
              !LeaderboardsStore.isFetching &&
              setConfig({
                ...config,
                categories: config.categories === 'LIFE' ? '' : 'LIFE',
              })
            }
          >
            <MDBIcon icon="check" />
            LIFE
          </MDBBtn>
        </MDBCol>
        <MDBCol sm="4">
          <MDBBtn
            className={
              'btn-blue btn-block ' +
              (config.categories === 'SENIOR' ? ' btn-active' : '')
            }
            disabled={LeaderboardsStore.isFetching}
            onClick={() =>
              !LeaderboardsStore.isFetching &&
              setConfig({
                ...config,
                categories: config.categories === 'SENIOR' ? '' : 'SENIOR',
              })
            }
          >
            <MDBIcon icon="check" />
            SENIOR
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12">
          <LeaderboardAvAcaToggle
            config={config}
            setConfig={(c) => setConfig({ ...config, ...c })}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(LeaderboardFilterSelector)
