import { observer } from 'mobx-react-lite'
import React from 'react'
import { MDBCollapse, MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import { UIInputCheckbox } from './../../../../components/forms/form-fields'
import './ToggleAvAca.scss'

const ToggleAvAca = ({ isOpen, value, onChange }) => {
  return (
    <MDBCollapse isOpen={!!isOpen} id="ToggleAvAca">
      <MDBContainer className="mt-2">
        <MDBRow>
          <MDBCol>
            <UIInputCheckbox
              label="Remove ACA from AV totals?"
              id={`remove_aca_av`}
              name={`remove_aca_av`}
              checked={!!value}
              onChange={(evt) => onChange(!value)}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBCollapse>
  )
}

export default observer(ToggleAvAca)
