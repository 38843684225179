import AdminApi from './../../api/admin-api/admin-api'
import StorageService from './Storage.service'
import PermissionService from './Permission.service'
import UsertypeService from './Usertype.service'
import UserService from './User.service'
import UserAlertService from './UserAlert.service'
import UserFactory from './../factories/user.factory'
import { getHeadshot } from './../utilities/getHeadshot.function'

const getUserId = (ignoreAssumed) => {
  let userDetails = getUserDetailsFromStorage(ignoreAssumed)
  return userDetails ? userDetails.id : null
}

const get = (prop, ignoreAssumed) => {
  let userDetails = getUserDetailsFromStorage(ignoreAssumed)
  if (userDetails && userDetails.hasOwnProperty(prop)) return userDetails[prop]
}

const set = (prop, value, ignoreAssumed) => {
  let userDetails = getUserDetailsFromStorage(ignoreAssumed)
  userDetails[prop] = value
  setUserDetailsToStorage(userDetails, ignoreAssumed)
}

const isAssumed = () => {
  let u = getUserId(true),
    a = getUserId()

  return u && a && parseInt(u) !== parseInt(a)
}

// See PermissionService.canUser() for argument details.
const canUser = async (permission, andOr, ignoreAssumed) => {
  let userId = getUserId(ignoreAssumed)
  return await PermissionService.canUser(permission, userId, andOr)
}

const getUserTypeId = (ignoreAssumed) => {
  return get('usertype_id', ignoreAssumed)
}

const getUserType = async (ignoreAssumed) => {
  let usertypeId = getUserTypeId(ignoreAssumed),
    usertypes = []

  try {
    usertypes = await PermissionService.getUserTypes()
  } catch (ex) {
    console.error(
      'ERROR: Failed to fetch user types in UserProfileService.getUserType()',
      ex
    )
  }

  return usertypes
    .filter((u) => parseInt(u.id) === parseInt(usertypeId))
    .shift()
}

const getCommissionLevelId = (ignoreAssumed) => {
  let userDetails = getUserDetailsFromStorage(ignoreAssumed)
  return userDetails ? userDetails.commission_id : null
}

const getUserDetailsFromStorage = (ignoreAssumed) => {
  let details =
      !ignoreAssumed && StorageService.get('child_details')
        ? StorageService.get('child_details')
        : StorageService.get('user_details'),
    isJson = false

  if (details) {
    if (typeof details === 'string') {
      try {
        isJson = typeof JSON.parse(details) === 'object'
      } catch (ex) {
        isJson = false
      }
    }

    return isJson ? JSON.parse(details) : details
  }

  return undefined
}

const setUserDetailsToStorage = (userData, ignoreAssumed) =>
  StorageService.set(
    !ignoreAssumed && StorageService.get('child_details')
      ? 'child_details'
      : 'user_details',
    userData
  )

const getUserDetailsFromAPI = async (ignoreAssumed) => {
  return new Promise((resolve, reject) => {
    AdminApi.getProfileData(getUserId(ignoreAssumed))
      .then((result) => {
        if (
          result &&
          result.data &&
          result.data.data &&
          result.data.data.usertype_id
        )
          return resolve(result.data.data)
        reject()
      })
      .catch((error) => reject(error.message))
  })
}

const fetchAndStoreUserDetails = async (ignoreAssumed) => {
  let userDetails = await getUserDetailsFromAPI(ignoreAssumed)

  if (userDetails && userDetails.id) {
    if (!getUserId()) {
      // userDetails
      StorageService.set('user_details', userDetails)
      return Promise.resolve(userDetails)
    } else if (parseInt(getUserId(true)) === parseInt(userDetails.id)) {
      // userDetails
      StorageService.set('user_details', userDetails)
      return Promise.resolve(userDetails)
    } else if (parseInt(getUserId()) === parseInt(userDetails.id)) {
      // assume userDetails
      StorageService.set('child_details', userDetails)
      return Promise.resolve(userDetails)
    }
  }

  return Promise.reject(false)
}

const getUserMetas = async (metaKey, ignoreAssumed) => {
  let userId = getUserId(ignoreAssumed)

  if (userId) return await UserService.getUserMetas(metaKey, userId)

  console.error(
    'ERROR: Unable to retrieve user_metas with user_id from storage.',
    {
      user_id: userId,
      meta_key: metaKey,
    }
  )
  return undefined
}

const upsertUserMeta = async (metaKey, metaValue, ignoreAssumed) => {
  let userId = getUserId(ignoreAssumed)

  if (userId)
    return await UserService.upsertUserMeta(metaKey, metaValue, userId)

  console.error(
    'ERROR: Unable to upsert user_meta with user_id from storage.',
    { user_id: userId, meta_key: metaKey }
  )
  return undefined
}

const isA = (usertype, ignoreAssumed) => {
  let usertypeId = getUserTypeId(ignoreAssumed)
  usertype = Array.isArray(usertype) ? usertype : [usertype]
  for (let u in usertype)
    if (UsertypeService.isA(usertype[u], usertypeId)) return true
  return false
}

const getDownline = async (ignoreAssumed) => {
  let userId = getUserId(ignoreAssumed)

  if (userId) return await UserService.getDownline(userId)

  console.error(
    'ERROR: Unable to fetch user downline with user_id from storage.',
    { user_id: userId }
  )
  return undefined
}

const resyncCorpEmail = async () => {
  return new Promise((resolve, reject) => {
    AdminApi.resyncCorpEmail(getUserId())
      .then((result) => {
        if (result && result?.data?.payload)
          return resolve(result?.data?.payload)
        reject()
      })
      .catch((error) => reject(error.message))
  })
}

const fetchAlerts = async (ignoreAssumed) => {
  let userId = getUserId(ignoreAssumed),
    alerts = userId
      ? await UserAlertService.search({
          search: { user_id: userId },
          pagination: { per_page: 999 },
          orderBy: { id: 'DESC' },
        })
      : []

  return alerts && Array.isArray(alerts?.models) ? alerts.models : alerts
}

const instance = async (ignoreAssumed) =>
  await UserFactory.findById(getUserId(ignoreAssumed))

const track = async (
  { event_type, event_descrip, related_id, related_model, payload },
  ignoreAssumed
) => {
  const userId = getUserId(ignoreAssumed),
    User = userId ? await instance(ignoreAssumed) : null

  if (!User) return

  if (!event_type)
    throw new Error(
      `Unable to track event.  Invalid User instance or event_type. Event Type: ${event_type}`
    )

  payload = payload && typeof payload === 'object' ? payload : {}
  if (!ignoreAssumed) {
    if (isAssumed()) payload.is_assumed_by = getUserId(true)
  }
  payload = JSON.stringify(payload)

  let createdAt = new Date()
  return await (
    await User.history(true).create({
      event_type,
      event_descrip,
      related_id,
      related_model,
      payload,
      created_at:
        `${createdAt.getUTCFullYear()}-${
          createdAt.getUTCMonth() + 1
        }-${createdAt.getUTCDate()} ` +
        `${createdAt.getUTCHours()}:${createdAt.getUTCMinutes()}:${createdAt.getUTCSeconds()}.${createdAt.getUTCMilliseconds()}`,
    })
  ).save()
}

const getPipLevel = async (ignoreAssumed) => {
  let userId = getUserId(ignoreAssumed)
  if (userId) return await UserService.getPipLevel(userId)
}

const getPipLevelByYear = async (year, ignoreAssumed) => {
  let userId = getUserId(ignoreAssumed)
  if (userId) return await UserService.getPipLevelByYear(userId, year)
}

const getTeamPipLevel = async (ignoreAssumed) => {
  let userId = getUserId(ignoreAssumed)
  if (userId) return await UserService.getTeamPipLevel(userId)
}

const getTeamPipLevelByYear = async (year, ignoreAssumed) => {
  let userId = getUserId(ignoreAssumed)
  if (userId) return await UserService.getTeamPipLevelByYear(userId, year)
}

const getRegion = async (ignoreAssumed) => {
  let userId = getUserId(ignoreAssumed)

  if (userId) return await UserService.getRegion(userId)

  console.error(
    'ERROR: Unable to fetch user downline with user_id from storage.',
    { user_id: userId }
  )
  return undefined
}

const getDistrict = async (ignoreAssumed) => {
  let userId = getUserId(ignoreAssumed)

  if (userId) return await UserService.getDistrict(userId)

  console.error(
    'ERROR: Unable to fetch user downline with user_id from storage.',
    { user_id: userId }
  )
  return undefined
}

const hasMetRequirements = async (requirements, ignoreAssumed) => {
  requirements = (
    Array.isArray(requirements) ? requirements : [requirements]
  ).filter((n) => typeof n === 'object')

  const userId = getUserId(ignoreAssumed)
  const response = await UserService.hasMetRequirements(userId, {
    requirements,
  })

  return response
}

const UserProfileService = {
  set: set,
  get: get,
  isA: isA,
  canUser: canUser,
  isAssumed: isAssumed,
  getUserId: getUserId,
  getUserMetas: getUserMetas,
  upsertUserMeta: upsertUserMeta,
  getCurrentUserTypeId: getUserTypeId,
  getUserType: getUserType,
  getUserDetails: getUserDetailsFromStorage,
  getCommissionLevelId: getCommissionLevelId,
  fetchAndStoreUserDetails: fetchAndStoreUserDetails,
  getUserDetailsFromAPI: getUserDetailsFromAPI,
  getDownline: getDownline,
  resyncCorpEmail: resyncCorpEmail,
  fetchAlerts: fetchAlerts,
  getHeadshot: (profile) =>
    getHeadshot(
      profile ? profile : { id: get('id'), u_picture: get('u_picture') }
    ),
  instance,
  track,
  getPipLevel,
  getPipLevelByYear,
  getTeamPipLevel,
  getTeamPipLevelByYear,
  getRegion,
  getDistrict,
  hasMetRequirements,
}

export default UserProfileService
