import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { MDBContainer, MDBBtn, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import LeaderboardDateSelector from './../LeaderboardDateSelector/LeaderboardDateSelector.component'
import LeaderboardFilterSelector from './../LeaderboardFilterSelector/LeaderboardFilterSelector.component'
import LeaderboardDateFilter from './../LeaderboardDateFilter/LeaderboardDateFilter.component'
import LeaderboardAvAcaToggle from './../LeaderboardAvAcaToggle/LeaderboardAvAcaToggle.component'
import LeaderboardsStore from './../../store/LeaderboardsPage.store'
import './LeaderboardConfigurator.scss'

const today = moment()

const toHuman = (str) =>
  str
    ? typeof str === 'string'
      ? moment(str, 'YYYY-MM-DD').format('MMM DD, YYYY')
      : str
    : null

const customToOutputMode = (start, stop) => {
  let diff = moment(stop, 'YYYY-MM-DD').diff(
    moment(start, 'YYYY-MM-DD'),
    'days'
  )

  return diff < 8 ? 'weekly' : diff < 366 ? 'quarterly' : 'monthly'
}

const LeaderboardConfigurator = (props) => {
  const [config, setConfig] = useState({
    type: 'points',
    start: moment(today).startOf('isoweek').format('YYYY-MM-DD'),
    stop: moment(today).endOf('isoweek').format('YYYY-MM-DD'),
    categories: '',
    agent_type: 'user_id',
    output_mode: 'weekly',
    remove_aca_av: false,
  })

  const renderCurrentDatesHeader = () => {
    let interval = ''

    switch (config.output_mode) {
      case 'weekly':
        interval = `: Week ${moment(config.start, 'YYYY-MM-DD').isoWeek()}`
        break
      case 'monthly':
        interval = `: Month ${
          parseInt(moment(config.start, 'YYYY-MM-DD').month()) + 1
        }`
        break
      case 'quarterly':
        interval = `: Quarter ${moment(config.start, 'YYYY-MM-DD').quarter()}`
        break
      case 'yearly':
        interval = `: Year ${moment(config.start, 'YYYY-MM-DD').year()}`
        break
      case 'custom':
      default:
        return (
          <>
            <h4>Custom Date Reporting</h4>
            <h5>
              {toHuman(config.start)} thru {toHuman(config.stop)}
            </h5>
          </>
        )
    }

    return (
      <>
        <h4>
          {config.output_mode.charAt(0).toUpperCase()}
          {config.output_mode.substr(1)} Reporting{interval}
        </h4>
        <h5>
          {toHuman(config.start)} thru {toHuman(config.stop)}
        </h5>
      </>
    )
  }

  const toggleAgentType = (agent_type) =>
    !LeaderboardsStore.isFetching &&
    setConfig((config) => ({ ...config, agent_type }))

  useEffect(() => {
    if (!LeaderboardsStore.isFetching) {
      LeaderboardsStore.updAndFetch({
        type: config.type,
        start: config.start,
        stop: config.stop,
        categories: config.categories,
        agent_type: config.agent_type,
        remove_aca_av: config.remove_aca_av,
        output_mode:
          config.output_mode === 'custom'
            ? customToOutputMode(config.start, config.stop)
            : config.output_mode,
        opts: { remove_aca_av: config?.remove_aca_av },
      })
    }
  }, [
    config.type,
    config.start,
    config.stop,
    config.categories,
    config.agent_type,
    config.output_mode,
    config.remove_aca_av,
  ])

  toast.configure()

  return (
    <MDBContainer id="LeaderboardConfigurator" fluid>
      <MDBRow className="leaderboard-links">
        <MDBCol lg="6" className="float-left">
          <MDBRow>
            <MDBCol size="12" md="8">
              {renderCurrentDatesHeader()}
              <MDBRow>
                <MDBCol size="12" className="d-none d-md-flex">
                  {/*<LeaderboardAvAcaToggle config={config} setConfig={c => setConfig((config) => ({ ...config, ...c }))} />*/}
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol size="6" md="2" className="agent-type-selector">
              <MDBBtn
                block
                disabled={config?.agent_type === 'ba_team_id'}
                onClick={() => toggleAgentType('ba_team_id')}
                type="button"
              >
                BA Teams&nbsp;
                {config?.agent_type === 'ba_team_id' ? (
                  <MDBIcon icon="check-circle" />
                ) : (
                  <MDBIcon far icon="circle" />
                )}
              </MDBBtn>
              <MDBBtn
                block
                disabled={config?.agent_type === 'user_id'}
                onClick={() => toggleAgentType('user_id')}
                type="button"
              >
                Sig Agents&nbsp;
                {config?.agent_type === 'user_id' ? (
                  <MDBIcon icon="check-circle" />
                ) : (
                  <MDBIcon far icon="circle" />
                )}
              </MDBBtn>
              <MDBBtn
                block
                disabled={config?.agent_type === 'sub_agent_id'}
                onClick={() => toggleAgentType('sub_agent_id')}
                type="button"
              >
                CSR/BAs&nbsp;
                {config?.agent_type === 'sub_agent_id' ? (
                  <MDBIcon icon="check-circle" />
                ) : (
                  <MDBIcon far icon="circle" />
                )}
              </MDBBtn>
            </MDBCol>
            <MDBCol size="6" md="2" className="agent-type-selector">
              <MDBBtn
                block
                disabled={config?.agent_type === 'district'}
                onClick={() => toggleAgentType('district')}
                type="button"
              >
                District Teams&nbsp;
                {config?.agent_type === 'district' ? (
                  <MDBIcon icon="check-circle" />
                ) : (
                  <MDBIcon far icon="circle" />
                )}
              </MDBBtn>
              <MDBBtn
                block
                disabled={config?.agent_type === 'region'}
                onClick={() => toggleAgentType('region')}
                type="button"
              >
                Region Teams&nbsp;
                {config?.agent_type === 'region' ? (
                  <MDBIcon icon="check-circle" />
                ) : (
                  <MDBIcon far icon="circle" />
                )}
              </MDBBtn>
              <MDBBtn
                block
                disabled={config?.agent_type === 'division'}
                onClick={() => toggleAgentType('division')}
                type="button"
              >
                Division Teams&nbsp;
                {config?.agent_type === 'division' ? (
                  <MDBIcon icon="check-circle" />
                ) : (
                  <MDBIcon far icon="circle" />
                )}
              </MDBBtn>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol>
              <LeaderboardFilterSelector
                config={config}
                setConfig={(c) => setConfig((config) => ({ ...config, ...c }))}
              />
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol lg="6" className="float-right">
          <LeaderboardDateFilter
            config={config}
            setConfig={(c) => setConfig((config) => ({ ...config, ...c }))}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-2">
        <MDBCol>
          <LeaderboardDateSelector
            config={config}
            setConfig={(c) => setConfig((config) => ({ ...config, ...c }))}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(LeaderboardConfigurator)
