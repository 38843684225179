import React from 'react'
import { observer } from 'mobx-react-lite'
import { MDBAlert, MDBSwitch, MDBRow, MDBCol, MDBContainer } from 'mdbreact'

import './ToggleAutoAwards.component.scss'

const ToggleAutoAwards = ({ ControlStore }) => {
  const toggle = async () => {
    try {
      await ControlStore.toggleAutoAwards()
    } catch (ex) {
      console.error(`${ex}`)
    }
  }

  const getColor = () => {
    if (ControlStore.isLoading) return 'warning'
    if (ControlStore.preventAutoAwards === false) return 'success'
    if (ControlStore.preventAutoAwards === true) return 'danger'
    return 'secondary'
  }

  return (
    <MDBAlert color={getColor()}>
      <MDBContainer
        fluid
        id="ToggleAutoAwardsComponent"
        className={
          ControlStore.isLoading
            ? 'is-loading'
            : ControlStore.preventAutoAwards === true
            ? 'is-activated'
            : 'is-deactivated'
        }
      >
        <MDBRow>
          <MDBCol size="12" sm="6" className="label-wrapper">
            <div>
              <label>
                Pause Auto-Awards?&nbsp;
                {ControlStore.isLoading ? (
                  <>
                    <span>
                      <i className="fa fa-spin fa-spinner"></i>
                    </span>
                  </>
                ) : (
                  <></>
                )}
                {ControlStore.preventAutoAwards === true ? (
                  <>
                    <br />
                    <small>
                      <strong>(Auto-Awards are Inactive)</strong>
                    </small>
                  </>
                ) : (
                  <></>
                )}
                {ControlStore.preventAutoAwards === false ? (
                  <>
                    <br />
                    <small>
                      <strong>(Auto-Awards are Active)</strong>
                    </small>
                  </>
                ) : (
                  <></>
                )}
              </label>
              <div className="switch-wrapper">
                <MDBSwitch
                  labelLeft={
                    ControlStore.preventAutoAwards === true ? (
                      <span style={{ color: '#a10a0a', fontWeight: 600 }}>
                        Paused
                      </span>
                    ) : (
                      'Pause'
                    )
                  }
                  labelRight={
                    ControlStore.preventAutoAwards === false ? (
                      <span style={{ color: '#1c802b', fontWeight: 600 }}>
                        Activated
                      </span>
                    ) : (
                      'Activate'
                    )
                  }
                  disabled={!!ControlStore.isLoading}
                  checked={ControlStore.preventAutoAwards === false}
                  onChange={toggle}
                />
              </div>
            </div>
          </MDBCol>
          <MDBCol size="12" sm="6" className="switch-wrapper">
            Paused Auto-Awards prevents wallets from receiving automated BUCK$
            payments.
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBAlert>
  )
}

export default observer(ToggleAutoAwards)
