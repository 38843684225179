import React, { useEffect, useState } from 'react'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import PageHeading from './../../components/shared/PageHeading.component'
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact'
import UploadWidget from './components/UploadWidget/UploadWidget.component'
import appConstants from '../../constants/appConstants'

const MyDocsPage = (props) => (
  <DashboardLayout>
    <main id="MyDocsPage" className="mainSection pb-5">
      <MDBContainer fluid className="mt-5">
        <PageHeading
          label="My Docs"
          url={props?.match?.url}
          from={props?.location?.state?.from}
        />
        <div>
          <MDBRow>
            {appConstants.doc_types.map((type, index) => {
              return (
                <MDBCol size="12" key={index} className="mb-4">
                  <UploadWidget type={type} />
                </MDBCol>
              )
            })}
          </MDBRow>
        </div>
      </MDBContainer>
    </main>
  </DashboardLayout>
)

export default MyDocsPage
